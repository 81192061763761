@import './variables';
@import-normalize; 

* {
  padding: 0;
  margin: 0;
  outline: none;
}

*:after,
*:before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  background: #071430;
  font-family: 'InputMono';
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: rgba(255,255,255,.9);
}

h1, h2, h3 {
  font-family: 'InputMono';
  text-transform: uppercase;
  margin: 0;
}

h1 {
  font-size: 80px;
  line-height: 102px;
  font-weight: 800;
  color: rgba(255,255,255,.9);
}

h2 {
  font-size: 72px;
  line-height: 108px;
  font-weight: 500;
  color: rgba(255,255,255,.9);
}

.container {
  width: 1684px;
  margin: 0 auto;
  position: relative;
}

a.btn,
button.btn {
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  text-transform: uppercase;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &._disabled {
    pointer-events: none;
    background: rgba(255,255,255,.2);

    &:after {
      display: none;
    }
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: transform .25s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &--send {

    &._disabled {

      &:before {
        opacity: 1;
        visibility: visible;
      }

      span {
        color: transparent;
      }
    }

    &:before {
      content: '';
      width: 52px;
      height: 52px;
      background-image: url('../static/images/loading.gif');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      opacity: 0;
      visibility: hidden;
      transition: all .2s ease-in-out;
    }
  }

  &__type-1 {
    background-color: rgba(255,255,255,.3);
    border: 2px solid #FFFFFF;

    &:after {
      transform: translateY(82px);
    }

    &:hover {

      &:after {
        transform: translateY(0);
      }
      
      span {
        color: rgba(33,22,60,.9);
      }
    }
  }

  &__type-2 {
    height: 62px;
    background-color: rgba(255,255,255,.3);
    border: 2px solid #FFFFFF;
    border-radius: 2px;

    &:after {
      transform: translateY(62px);
    }

    span {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
    }

    &:hover {

      &:after {
        transform: translateY(0);
      }

      span {
        color: rgba(33,22,60,.9);
      }
    }
  }

  &__type-3 {
    height: 62px;
    background-color: rgba(15, 65, 180, 0.3);
    border: 2px solid #0F41B4;
    border-radius: 2px;

    &._disabled {
      border: none;
    }

    &:after {
      background-color: #0F41B4;
      transform: translateY(62px);
    }

    span {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #fff;
    }

    &:hover {

      &:after {
        transform: translateY(0);
      }
    }
  }

  &__type-4 {
    height: 82px;
    background-color: rgba(255, 255, 255, 0.2);

    &:after {
      background-color: #fff;
      transform: translateY(82px);
    }

    &:hover {

      &:after {
        transform: translateY(0);
      }

      span {
        color: rgba(33,22,60,.9);
      }
    }
  }

  span {
    font-family: 'InputMono';
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    color: rgba(255,255,255,.9);
    transition: color .25s ease-in-out;
    position: relative;
    z-index: 2;
  }
}

// desktop
@media screen and (min-width: $desktop1_min) and (max-width: $desktop1_max) {
  
  h1 {
    font-size: 64px;
    line-height: 77px;
  }
  
  h2 {
    font-size: 48px;
    line-height: 72px;
  }
  
  .container {
    width: 1280px;
  }
  
  a.btn,
  button.btn {
    height: 66px;
  
    &__type-2 {
      height: 46px;

      span {
        font-size: 16px;
        line-height: 20px;
      }
    }
  
    &__type-3 {
      height: 46px;

      span {
        font-size: 16px;
        line-height: 20px;
      }
    }
  
    &__type-4 {
      height: 56px;
    }
  
    span {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@media screen and (min-width: $desktop2_min) and (max-width: $desktop2_max) {
  
  h1 {
    font-size: 52px;
    line-height: 65px;
  }
  
  h2 {
    font-size: 36px;
    line-height: 50px;
  }

  a.btn,
  button.btn {
    height: 56px;
  
    &__type-2 {
      height: 40px;

      span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  
    &__type-3 {
      height: 40px;

      span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  
    &__type-4 {
      height: 56px;
    }
  
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
  
  .container {
    width: 1160px;
  }
}

// tablet-horizontal
@media screen and (min-width: $tablet-horizontal_min) and (max-width: $tablet-horizontal_max) {
  
  h1 {
    font-size: 50px;
    line-height: 62px;
  }
  
  h2 {
    font-size: 42px;
    line-height: 52px;
  }
  
  .container {
    width: 926px;
  }
  
  a.btn,
  button.btn {
    height: 48px;

    &__type-1 {
      background-color: rgba(255,255,255,.3);
      border: 2px solid #FFFFFF;
  
      &:after {
        transform: translateY(48px);
      }
  
      &:hover {
  
        &:after {
          transform: translateY(48px);
        }
        
        span {
          color: rgba(255,255,255,.9);
        }
      }
    }
  
    &__type-2 {
      height: 46px;
  
      &:after {
        transform: translateY(48px);
      }
  
      span {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
      }
  
      &:hover {
  
        &:after {
          transform: translateY(48px);
        }
  
        span {
          color: rgba(255,255,255,.9);
        }
      }
    }
  
    &__type-3 {
      height: 46px;
  
      &:after {
        transform: translateY(48px);
      }
  
      span {
        font-size: 16px;
        line-height: 20px;
      }
  
      &:hover {
  
        &:after {
          transform: translateY(0);
        }
      }
    }
  
    &__type-4 {
      height: 46px;
  
      &:after {
        transform: translateY(48px);
      }
  
      &:hover {
  
        &:after {
          transform: translateY(48px);
        }
  
        span {
          color: rgba(255,255,255,.9);
        }
      }
    }
  
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

// tablet-portrait
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-portrait_max) {

  h1 {
    font-size: 80px;
    line-height: 82px;
  }
  
  h2 {
    font-size: 42px;
    line-height: 52px;
  }
  
  .container {
    width: 720px;
  }
  
  a.btn,
  button.btn {
    height: 60px;

    &__type-1 {
      background-color: rgba(255,255,255,.3);
      border: 2px solid #FFFFFF;
  
      &:after {
        transform: translateY(60px);
      }
  
      &:hover {
  
        &:after {
          transform: translateY(60px);
        }
        
        span {
          color: rgba(255,255,255,.9);
        }
      }
    }
  
    &__type-2 {
      height: 46px;
  
      &:after {
        transform: translateY(60px);
      }
  
      span {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
      }
  
      &:hover {
  
        &:after {
          transform: translateY(60px);
        }
  
        span {
          color: rgba(255,255,255,.9);
        }
      }
    }
  
    &__type-3 {
      height: 46px;
  
      &:after {
        transform: translateY(60px);
      }
  
      span {
        font-size: 16px;
        line-height: 20px;
      }
  
      &:hover {
  
        &:after {
          transform: translateY(0);
        }
      }
    }
  
    &__type-4 {
      height: 46px;
  
      &:after {
        transform: translateY(60px);
      }
  
      &:hover {
  
        &:after {
          transform: translateY(60px);
        }
  
        span {
          color: rgba(255,255,255,.9);
        }
      }
    }
  
    span {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {

  h1 {
    font-size: 40px;
    line-height: 48px;
  }
  
  h2 {
    font-size: 22px;
    line-height: 33px;
  }

  .container {
    width: 90%;
  }

  a.btn,
  button.btn {
    height: 56px;

    &:after {
      display: none;
    }


    &--send {


      &._disabled {

        span {
          color: transparent !important;
        }
      }

      &:before {
        background-image: url('../static/images/loading_dark.gif');
      }
    }

    &__type-1 {
      background-color: rgba(255,255,255,.3);
      border: 2px solid #FFFFFF;

      &:after {
        transform: translateY(82px);
      }

      &:hover {

        &:after {
          display: none;
        }
        
        span {
          color: rgba(255,255,255,.9);
        }
      }
    }

    &__type-2 {
      height: 56px;

      &:after {
        display: none;
      }

      span {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
      }

      &:hover {

        &:after {
          display: none;
        }

        span {
          color: rgba(255,255,255,.9);
        }
      }
    }

    &__type-3 {

      &:after {
        display: none;
      }

      &:hover {

        &:after {
          display: none;
        }
      }
    }

    &__type-4 {
      height: 56px;

      &:after {
        background-color: #fff;
        transform: translateY(82px);
      }

      &:hover {

        &:after {
          display: none;
        }

        span {
          color: rgba(255,255,255,.9);
        }
      }
    }

    span {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
