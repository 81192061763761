@import '../../../styles/variables';

.header {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(-80px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out, background .25s ease-in-out;

  &._dark {
    background: rgba(7,20,48,.8);
  }

  &._show {
    opacity: 1;
    transform: translateY(0);
  }

  .container {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }

  &__logo {
    width: 120px;

    img {
      width: 100%;
    }
  }

  &__phone {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      color: rgba(255,255,255,.5);
    }

    a {
      font-family: 'InputMono', sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #fff;
      transition: all .25s ease-in-out;
      margin-left: 8px;

      &:hover {
        color: #fff;
      }
    }
  }
}

// from 1200 to 1700
@media screen and (min-width: $desktop2_min) and (max-width: $desktop1_max) {

  .header {
    height: 68px;
    z-index: 100;

    .container {
      height: 68px;
    }

    &__logo {
      width: 103px;
    }

    &__phone {

      a {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

@media screen and (min-width: $desktop2_min) and (max-width: $desktop1_max) and (max-height: 650px) {
  .header {
    background: rgba(7,20,48,.8);
  }
}

// tablet-horizontal
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-horizontal_max) {

  .header {
    height: 60px;
    transform: translateY(-60px);

    .container {
      height: 60px;
    }

    &__logo {
      width: 96px;
    }

    &__phone {

      a {
        font-size: 16px;
        color: rgba(255,255,255,.8);

        &:hover {
          color: rgba(255,255,255,.8);
        }
      }
    }
  }
}

// tablet-portrait
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-portrait_max) {
  .header {
    height: 60px;
    background: rgba(7,20,48,.8);
    border-bottom: 1px solid rgba(255,255,255,.2);
    opacity: 1;
    transform: translateY(0);
    z-index: 100;
  }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {

  .header {
    height: 60px;
    border-bottom: 1px solid rgba(255,255,255,.2);
    opacity: 1;
    transform: translateY(0);
    z-index: 100;

    .container {
      width: 100%;
      height: 60px;
      padding-left: 5%;
      margin: 0;
      box-sizing: border-box;
    }

    &__logo {
      width: 92px;
    }

    &__phone {
      width: 54px;
      height: 60px;
      border-left: 1px solid rgba(255,255,255,.2);
      box-sizing: border-box;
      overflow: hidden;

      p {
        display: none;
      }

      a {
        width: 100%;
        height: 60px;
        display: block;
        color: transparent;
        position: relative;
        margin-left: 0;

        &:hover {
          color: transparent;
        }

        &:after {
          content: '';
          width: 15px;
          height: 15px;
          background-image: url('../../../static/images/icons/call.svg');
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
}
