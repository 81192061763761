@import '../../../styles/variables';

$xsize: 377px;
$ysize: 207px;

.notFound {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #071430;
    padding-top: 80px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: rgba(255,255,255,.15);
      position: absolute;
      top: 80px;
      left: 0;
    }

    &__main {
        width: 400px;
        position: relative;
        z-index: 20;

        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #fff;
            margin: 0;
            margin-bottom: 30px;
        }

        a {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: #fff;
            position: relative;

            &:before {
                content: '';
                width: calc(100% - 31px);
                height: 1px;
                background-color: #fff;
                position: absolute;
                left: 0;
                bottom: -3px;
                z-index: 5;
                opacity: .6;
                transition: opacity .2s ease-in-out;
            }

            &:after {
                content: '';
                width: 21px;
                height: 17px;
                background-image: url('../../../static/images/icons/arrow-link.svg');
                background-size: contain;
                background-repeat: no-repeat;
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
                transition: transform .2s ease-in-out;
            }

            &:hover {

                &:before {
                    opacity: 1;
                  }

                &:after {
                  transform: translateX(8px);
                }
            }
        }
    }

    &__image {
        width: $xsize;
        height: $ysize;
        position: absolute;
        top: 80px;
        left: 0;
        z-index: 10;
        animation: x-error 13s linear infinite alternate;

        img {
            width: $xsize;
            height: $ysize;
            animation: y-error 7s linear infinite alternate;
        }
    }
}

@keyframes x-error {
  100% {
    transform: translateX( calc(100vw - #{$xsize}) );
  }
}

@keyframes y-error {
  100% {
    transform: translateY( calc(100vh - 80px - #{$ysize}) );
  }
}

@media screen and (min-width: $desktop2_min) and (max-width: $desktop1_max) {
  .notFound {
    padding-top: 68px;

    &:before {
      top: 68px;
    }
  }
}

@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {

  $xsize: 240px;
  $ysize: 140px;

  .notFound {
    padding-top: 60px;

    &:before {
      display: none;
    }

    &__main {
      width: 90%;

      a {

        &:hover {

          &:before {
            opacity: 1;
          }

          &:after {
            transform: translateX(0);
          }
        }
      }
    }

    &__image {
      width: $xsize;
      height: $ysize;
      top: 60px;
      animation: x-error 13s linear infinite alternate;

      img {
        width: $xsize;
        height: $ysize;
        animation: y-error 7s linear infinite alternate;
      }
    }
  }

  @keyframes x-error {
    100% {
      transform: translateX( calc(100vw - #{$xsize}) );
    }
  }
  
  @keyframes y-error {
    100% {
      transform: translateY( calc(100vh - 60px - #{$ysize}) );
    }
  }
}
