@import '../../../styles/variables';

.info  {
  padding-top: 120px;

  &__content {
    width: 456px;
    margin: 0 auto
  }

  &__block {
    width: 100%;
  }
}

.field {

  &__item {
    position: relative;

    &._radio {

      label {
        position: relative;
        top: auto;
        left: auto;
      }

      &._valid {
        
        label {
          color: rgba(255,255,255,.8);
        }
      }

      &._error {

        label {
          color:#E40031;
        }
      }
    }

    &._valid {
      
      label {
        transform: translateY(-40px);
        color: rgba(255,255,255,.8);
      }

      input {
        border-color: #0F41B4;
      }

      span.params-field {
        color: #fff;
      }
    }

    &._error {

      label {
        transform: translateY(-40px);
        color:#E40031;
      }

      input {
        border-color: #E40031;

        &:focus {
          border-color: #E40031;

          & ~ label {
            color: #E40031;
          }
        }
      }

      span.error-field {
        display: block;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 80px;
    }

    label {
      font-family: 'InputMono';
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: rgba(255,255,255,.3);
      letter-spacing: -0.01em;
      text-transform: uppercase;
      position: absolute;
      top: 10px;
      left: 0;
      transition: all .25s ease-in-out;

      &._active {
        color: #fff;
      }
    }

    input {
      width: 100%;
      height: 60px;
      padding-right: 30px;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(255,255,255,.15);
      box-sizing: border-box;
      font-family: 'InputMono';
      font-size: 56px;
      line-height: 60px;
      font-weight: 500;
      color: #fff;
      letter-spacing: -0.01em;
      text-transform: uppercase;
      border-radius: 0;
      transition: all .25s ease-in-out;

      &:focus {
        border-color: #0F41B4;

        & ~ label {
          transform: translateY(-40px);
          color: rgba(255,255,255,.8);
        }
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::-ms-clear {
        display: none;
      }
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    span {
      position: absolute;
      font-family: 'InputMono';

      &.params-field {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: rgba(255,255,255,.3);
        letter-spacing: -0.01em;
        text-transform: uppercase;
        position: absolute;
        right: 0;
        bottom: 4px;
        transition: all .25s ease-in-out;
      }

      &.error-field {
        display: none;
        width: 100%;
        padding: 8px 16px;
        background-color: rgba(255,255,255,.1);
        box-sizing: border-box;
        font-size: 12px;
        line-height: 12px;
        font-weight: 300;
        color: rgba(255,255,255,.9);
        left: 0;
        bottom: -28px;
      }
    }
  }

  &__radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;

    &__item {
      width: 110px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: none;
      background: transparent;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;

      &._active {

        &:after {
          opacity: 1;
          visibility: visible;
        }

        span {
          color: rgba(255,255,255,.8);
        }
      }

      &:before,
      &:after {
        content: '';
        display: block;
        border-radius: 50%;
      }

      &:before {
        width: 30px;
        min-width: 30px;
        height: 30px;
        margin-right: 16px;
        background-color: #0F41B4;
        opacity: .2;
      }

      &:after {
        width: 14px;
        height: 14px;
        border: 3px solid #fff;
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: all .25s ease-in-out;
      }

      &:first-of-type {
        margin-right: 56px;
      }

      span {
        position: relative;
        font-family: 'InputMono';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: rgba(255,255,255,.3);
        letter-spacing: -0.01em;
        text-transform: uppercase;
        transition: all .25s ease-in-out;
      }
    }
  }
}

// desktop
@media screen and (min-width: $desktop1_min) and (max-width: $desktop1_max) {

  .info  {
    padding-top: 64px;
  
    &__content {
      width: 324px;
    }
  }
  
  .field {
  
    &__item {
  
      &:not(:last-of-type) {
        margin-bottom: 68px;
      }
  
      label {
        font-size: 14px;
        line-height: 21px;
      }
  
      input {
        height: 48px;
        font-size: 32px;
        line-height: 38px;
      }
  
      span {
  
        &.params-field {
          font-size: 14px;
          line-height: 21px;
        }
  
        &.error-field {
          font-size: 10px;
          line-height: 10px;
          padding: 6px 10px;
          bottom: -22px;
        }
      }
    }
  
    &__radio {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 24px;
  
      &__item {
        width: 110px;

        &:first-of-type {
          margin-right: 56px;
        }
  
        span {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop2_min) and (max-width: $desktop2_max) {

  .info  {
    padding-top: 80px;
  
    &__content {
      width: 380px;
    }
  }
  
  .field {
  
    &__item {

  
      &:not(:last-of-type) {
        margin-bottom: 60px;
      }
  
      label {
        font-size: 14px;
        line-height: 10px;
      }
  
      input {
        height: 40px;
        font-size: 32px;
        line-height: 40px;
      }
  
      span {
  
        &.params-field {
          font-size: 12px;
          line-height: 18px;
        }
  
        &.error-field {
          padding: 4px 10px;
          font-size: 10px;
          line-height: 10px;
          bottom: -18px;
        }
      }
    }
  
    &__radio {
      margin-top: 20px;
  
      &__item {
  
        span {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

// tablet-horizontal
@media screen and (min-width: $tablet-horizontal_min) and (max-width: $tablet-horizontal_max) {

  .info  {
    padding-top: 100px;
  
    &__content {
      width: 360px;
    }
  }
  
  .field {
  
    &__item {
  
      &._valid {
        
        label {
          transform: translateY(-34px);
        }
      }
  
      &._error {
  
        label {
          transform: translateY(-34px);
        }
      }
  
      &:not(:last-of-type) {
        margin-bottom: 64px;
      }
  
      input {
        height: 50px;
        font-size: 36px;
        line-height: 50px;
  
        &:focus {
  
          & ~ label {
            transform: translateY(-34px);
          }
        }
      }
  
      span {

        &.params-field {
          font-size: 14px;
          line-height: 22px;
          bottom: 6px;
        }
  
        &.error-field {
          padding: 4px 8px;
          font-size: 11px;
          line-height: 11px;
          bottom: -20px;
        }
      }
    }
  }
}

// tablet-portrait
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-portrait_max) {

  .info  {
    padding-top: 60px;
  
    &__content {
      width: 720px;
      margin: 0 auto;
    }

    &__block {
      width: 460px;
      margin: 0 auto;
    }
  }
  
  .field {
  
    &__item {
  
      &:not(:last-of-type) {
        margin-bottom: 80px;
      }
  
      input {
        width: 100%;
        height: 50px;
        padding-right: 30px;
        font-size: 40px;
        line-height: 50px;
      }
    }
  }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {
  
  .info  {
    padding-top: 40px;
  
    &__content {
      width: 90%;
      padding-bottom: 100px;
      margin: 0 auto;
    }
  }
  
  .field {
  
    &__item {
  
      label {
        font-size: 14px;
        line-height: 21px;
      }
  
      input {
        height: 46px;
        padding-right: 28px;
        font-size: 32px;
        line-height: 38px;
      }
  
  
      span {
  
        &.params-field {
          font-size: 14px;
          line-height: 21px;
        }
  
        &.error-field {
          padding: 4px 6px;
          font-size: 11px;
          line-height: 15px;
          top: 46px;
          bottom: auto;
        }
      }
    }
  
    &__radio {
      margin-top: 16px;
  
      &__item {

        &:first-of-type {
          margin-right: 46px;
        }
  
        span {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
