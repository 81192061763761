@import '../../../styles/variables';

.modalWindow {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: none;
    box-sizing: border-box;
    overflow-x: auto;
    width: 100vw;
    height: 100vh;
    padding: 100px 30px;
    background: rgba(7, 20, 48, .8);
  
    &._active {
        // display: block;
        display: flex;
        align-items: center;
        //justify-content: center;
    }
  
    &__wrapper {
        width: 580px;
        max-width: 580px;
        padding: 40px;
        margin: 0 auto;
        background-color: #002860;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
    }
  
    &__close {
        width: 25px;
        height: 25px;
        padding: 0;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        position: absolute;
        top: -15px;
        right: -40px;
        transition: transform .3s ease-in-out;
    
        &:hover {
            transform: rotate(90deg);
        }
    
        img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
  
    &__body {
        width: 100%;
        position: relative;
    }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {

    .modalWindow {
        padding: 100px 5%;
      
        &__wrapper {
            width: 100%;
            max-width: 100%;
            padding: 20px;
        }
      
        &__close {
            top: -40px;
            right: 0;
        
            &:hover {
                transform: rotate(0);
            }
        }
      
        &__body {
            width: 100%;
            position: relative;
        }
    }
  }