@import '../../../styles/variables';

.process {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  background-color: #071430;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,.15);
    position: absolute;
    left: 0;
    top: 79px;
    z-index: 1;
  }

  &__item {
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, rgba(7, 20, 48, 0.8) 33.18%, rgba(223, 0, 49, 0.8) 170.74%);
    overflow: hidden;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(7, 20, 48, 0.368) 0%, rgba(15, 65, 180, 0) 100%);
      transform: rotate(-180deg);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

// desktop
@media screen and (min-width: $desktop2_min) and (max-width: $desktop1_max) {
  
  .process {

    &:before {
      top: 67px;
    }
  }
}

// tablet-horizontal
@media screen and (min-width: $tablet-horizontal_min) and (max-width: $tablet-horizontal_max) {
  .process {
  
    &:before {
      top: 59px;
    }
  }
}

// tablet-portrait
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-portrait_max) {

  .process {
  
    &:before {
      top: 59px;
    }
  }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {
  
  .process {
    height: auto;
    // min-height: 100vh;

    &__item {
      height: auto;
      min-height: 100vh;

      &:nth-of-type(2) {
      
        .step__answers__item {
          justify-content: space-between;
          padding-left: 5%;
          box-sizing: border-box;

          &._active {
            border-top: transparent;

            .mobile-img {
              opacity: 1;
            }
          }

          .mobile-img {
            display: block;
            width: 108px;
            height: 116px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            opacity: .2;
            transition: opacity .25s ease-in-out;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }

    &:before {
      display: none;
    }
  }
}
