@import '../../../styles/variables';

.finishScreen {
  background: #071430;
}

.finishGuide {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(7, 20, 48, 0.8) 33.18%, rgba(223, 0, 49, 0.8) 170.74%);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 2;

  &._show {

    .finishGuide__complete__item:first-of-type {

      h2, p {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .finishGuide__data {
      opacity: 1;
    }

    .finishGuide__bg {

      &__item {
        opacity: 1;

        &:nth-of-type(1) {
          transform: translateX(0);
        }

        &:nth-of-type(2) {
          transform: translateY(0);
        }

        &:nth-of-type(3) {
          transform: translateY(0);
        }
      }
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,.15);
    position: absolute;
    left: 0;
    top: 79px;
    z-index: 2;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(7, 20, 48, 0.368) 0%, rgba(15, 65, 180, 0) 100%);
    transform: rotate(-180deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    transition: all .25s ease-in-out;

    &._hidden {
      opacity: 0;
      visibility: hidden;
    }

    &__item {
      opacity: 0;
      transition: opacity .2s ease-in-out, transform .4s ease-in-out;

      &:nth-of-type(1) {
        width: 948px;
        position: absolute;
        left: 0;
        bottom: -10px;
        transform: translateX(-100px);
        transition-delay: .6s;
      }

      &:nth-of-type(2) {
        width: 304px;
        position: absolute;
        left: 500px;
        bottom: -10px;
        transform: translateY(60px);
        transition-delay: .7s;
      }

      &:nth-of-type(3) {
        width: 232px;
        position: absolute;
        left: 770px;
        bottom: -10px;
        transform: translateY(60px);
        transition-delay: .8s;
      }

      img {
        width: 100%;
      }
    }
  }

  &__arrow {
    width: 946px;
    height: 230px;
    transition: all .25s ease-in-out;
    position: absolute;
    left: 0;
    bottom: 200px;
    z-index: 3;

    &._hide {
      opacity: 0;
      visibility: hidden;
    }

    img {
      width: 100%;
    }
  }

  &__complete {
    width: 760px;
    margin-top: 196px;
    margin-left: calc((100% - 1684px) / 2);
    box-sizing: border-box;
    position: relative;
    z-index: 3;

    &__item {
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all .25s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;

      &:nth-of-type(2) {

        h2 {
          font-size: 48px;
          line-height: 72px;
        }

        p {
          width: 100%;
        }
      }

      &:first-of-type {

        h2, p {
          opacity: 0;
          transform: translateY(60px);
          transition: all .25s ease-in-out;
        }

        h2 {
          transition-delay: .2s;
        }

        p {
          transition-delay: .3s;
        }
      }

      &._active {
        opacity: 1;
        visibility: visible;
      }

      h2 {
        margin-bottom: 16px;
      }

      p {
        width: 680px;
        font-family: 'InputMono';
        font-size: 24px;
        line-height: 41px;
        font-weight: 300;
        color: #fff;
        margin: 0;
      }
    }
  }

  &__data {
    width: 696px;
    height: calc(100vh - 80px);
    margin-top: 80px;
    border-left: 1px solid rgba(255,255,255,.15);
    box-sizing: border-box;
    position: relative;
    z-index: 3;
    opacity: 0;
    transition: opacity .4s .4s ease-in-out;

    &__item {
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all .25s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;

      &._active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__form {
    width: 456px;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 80px;
    margin: 0 auto;
    box-sizing: border-box;

    &__btn {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .field {

      &__item {
        
        &:not(:last-of-type) {
          margin-bottom: 56px;
        }

        &:first-of-type {
          margin-bottom: 112px;
        }

        &._active-phone {

          label {
            transform: translateY(-40px);
          }
        }

        input {
          font-size: 32px;
          line-height: 35px;
          letter-spacing: -0.01em;
          text-transform: lowercase;

          /* Change autocomplete styles in WebKit */
          &:-webkit-autofill,
          &:-webkit-autofill:hover, 
          &:-webkit-autofill:focus {
            -webkit-text-fill-color: #fff;
            -webkit-box-shadow: 0 0 0px 1000px transparent inset;
            transition: background-color 5000s ease-in-out 0s;
            background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
          }
        }
      }

      &__agreement {
        position: relative;

        label {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        span {
          transition: all .25s ease-in-out;

          &:before {
            content: "";
            display: block;
            width: 5px;
            height: 10px;
            border: 0 solid rgba(255,255,255,.8);
            border-width: 0 2px 2px 0;
            transform: rotateZ(45deg);
            margin: 1px 5px;
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
            transition: all .25s ease-in-out;
          }
        }

        input[type="checkbox"] + span {
          -webkit-appearance: none;
          width: 18px;
          min-width: 18px;
          height: 18px;
          border: 2px solid rgba(255,255,255,.1);
          outline: 0;
          margin: 0;
          margin-left: 0;
          margin-right: 16px;
          box-sizing: border-box;
          display: inline-block;
        }

        input[type="checkbox"] + span.error {
          border-color: #E40031;
        }

        input[type="checkbox"]:checked + span {
          border-color: rgba(255,255,255,.8);

          &:before {
            opacity: 1;
            visibility: visible;
          }
        }

        p {
          font-family: 'InputMono';
          font-size: 12px;
          line-height: 20px;
          font-weight: 300;
          color: rgba(255,255,255,.8);
        }

        a {
          color: rgba(255,255,255,.8);
          // text-decoration: underline;
        }

        span.error-field {
          width: 100%;
          padding: 8px 16px;
          background-color: rgba(255,255,255,.1);
          box-sizing: border-box;
          font-size: 12px;
          line-height: 12px;
          font-weight: 300;
          color: rgba(255,255,255,.9);
          position: absolute;
          left: 0;
          bottom: -28px;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  &__share {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__info {
      width: 544px;
      padding-bottom: 192px;
      margin: 0 auto;

      &__complete {

        p {
          width: 520px;
          font-family: 'InputMono';
          font-size: 24px;
          line-height: 41px;
          font-weight: 300;
          color: rgba(255,255,255,.5);
          margin: 0;
          margin-bottom: 64px;

          span {
            word-break: break-all;
          }
        }
      }

      &__want {

        .full-block {

          &__start {

            h4 {
              font-weight: 500;
              font-size: 24px;
              line-height: 34px;
              color: #fff;
              text-transform: uppercase;
              margin: 0;
              margin-bottom: 10px;
            }

            p {
              width: 500px;
              font-family: 'InputMono';
              font-size: 24px;
              line-height: 41px;
              font-weight: 300;
              color: rgba(255,255,255,.5);
              margin: 0;
              margin-bottom: 24px;

              span {
                word-break: break-all;
              }

              &:last-of-type {
                color: #fff;
              }

              a {
                color: rgba(255,255,255,.5);
                transition: color .25s ease-in-out;

                &:hover {
                  color: rgba(255,255,255,1);
                }
              }
            }
          }

          &__finish {

            p {
              width: 100%;
              font-family: 'InputMono';
              font-size: 24px;
              line-height: 41px;
              font-weight: 300;
              color: rgba(255,255,255,.5);
              margin: 0;
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    &__btns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a.btn {
        width: 260px;
        height: 62px;
        border-radius: 2px;

        &:first-of-type {
          height: 62px;
        }

        span {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }

    &__social {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

.social-share {
  display: flex;
  position: relative;

  &__item {
    width: 100%;
    height: 192px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    transition: all .25s ease-in-out;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      width: 252px;
      height: 1px;
      background-color: rgba(255,255,255,.1);
      position: absolute;
      top: 0;
    }

    &:nth-of-type(1) {

      &:before {
        left: 0;
      }

      &:after {
        content: '';
        width: 1px;
        height: 130px;
        background-color: rgba(255,255,255,.1);
        position: absolute;
        top: 36px; 
        right: 0;
      }
    }

    &:nth-of-type(2) {

      &:before {
        right: 0;
      }
    }

    &:hover {
      background: linear-gradient(180deg, rgba(16, 47, 122, 0) 0%, #102F7A 100%);;

      img {
        opacity: 1;
      }
    }

    img {
      width: 28px;
      opacity: .7;
      transition: opacity .25s ease-in-out;
    }
  }

  span.share-title {
    display: block;
    font-family: 'InputMono';
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

// desktop
@media screen and (min-width: $desktop1_min) and (max-width: $desktop1_max) {

  .finishGuide {

    &:before {
      top: 67px;
    }

    &__bg {
  
      &__item {
  
        &:nth-of-type(1) {
          width: 730px;
        }
  
        &:nth-of-type(2) {
          width: 210px;
          left: 420px;
        }
  
        &:nth-of-type(3) {
          width: 164px;
          left: 608px;
        }
      }
    }

    &__arrow {
      width: 604px;
      height: 148px;
      bottom: 166px;
    }
  
    &__complete {
      width: 630px;
      margin-top: 162px;
      margin-left: calc((100% - 1280px) / 2);
  
      &__item {
  
        p {
          width: 100%;
          font-size: 20px;
          line-height: 38px;
        }
      }
    }
  
    &__data {
      width: 484px;
      height: calc(100vh - 56px);
      margin-top: 68px;
    }
  
    &__form {
      width: 324px;
      height: calc(100vh - 68px);
      padding-bottom: 56px;
  
      .field {
  
        &__item {
  
          &:first-of-type {
            margin-bottom: 80px;
          }
  
          input {
            height: 40px;
            font-size: 16px;
            line-height: 18px;
          }
        }
  
        &__agreement {

          span {
  
            &:before {
              margin: 0 4px;
            }
          }
  
          input[type="checkbox"] + span {
            width: 16px;
            min-width: 16px;
            height: 16px;
            margin-right: 12px;
          }

          span.error-field {
            font-size: 10px;
            line-height: 10px;
            padding: 6px 10px;
            bottom: -22px;
          }
        }
      }
    }
  
    &__share {
      height: calc(100vh - 68px);
  
      &__info {
        width: 324px;
        padding-bottom: 100px;

        &__complete {

          p {
            width: 300px;
            font-size: 18px;
            line-height: 31px;
            margin-bottom: 32px;
          }
        }
  
        &__want {
  
          .full-block {
  
            &__start {
  
              h4 {
                font-size: 22px;
                line-height: 33px;
                margin-bottom: 8px;
              }
  
              p {
                width: 300px;
                font-size: 18px;
                line-height: 31px;
                margin-bottom: 16px;
  
                &:last-of-type {
                  margin-bottom: 20px;
                }
              }
            }
  
            &__finish {
  
              p {
                font-size: 18px;
                line-height: 31px;
              }
            }
          }
        }
      }
  
      &__btns {
        flex-wrap: wrap;

        a.btn {
          width: 100%;
          height: 46px;
  
          &:first-of-type {
            height: 46px;
            margin-bottom: 22px;
          }

          span {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
    }
  }
  
  .social-share {
  
    &__item {
      height: 134px;
  
      &:before {
        content: '';
        width: 158px;
      }
  
      &:nth-of-type(1) {
  
        &:after {
          height: 90px;
          top: 25px; 
        }
      }
  
      img {
        width: 24px;
      }
    }
  
    span.share-title {
      font-size: 12px;
      line-height: 20px;
      font-weight: 300;
      top: -8px;
    }
  }
}

@media screen and (min-width: $desktop2_min) and (max-width: $desktop2_max) {

  .finishGuide {

    &:before {
      top: 67px;
    }

    &__bg {

      &__item {
  
        &:nth-of-type(1) {
          width: 600px;
        }
  
        &:nth-of-type(2) {
          width: 180px;
          left: 346px;
        }
  
        &:nth-of-type(3) {
          width: 146px;
          left: 502px;
        }
      }
    }

    &__arrow {
      width: 560px;
      height: 140px;
      bottom: 140px;
    }
  
    &__complete {
      width: 520px;
      margin-top: 160px;
      margin-left: calc((100% - 1160px) / 2);
  
      &__item {

        &:nth-of-type(2) {

          h2 {
            font-size: 36px;
            line-height:56px;
          }
        }

        h2 {
          font-size: 40px;
          line-height: 50px;
        }

        p {
          width: 100%;
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  
    &__data {
      width: 460px;
      height: calc(100vh - 68px);
      margin-top: 68px;
    }
  
    &__form {
      width: 320px;
      height: calc(100vh - 68px);
      padding-bottom: 56px;
  
      .field {
  
        &__item {

          &:not(:last-of-type) {
            margin-bottom: 46px;
          }
  
          &:first-of-type {
            margin-bottom: 64px;
          }
  
          input {
            font-size: 24px;
            line-height: 28px;
            height: 46px;
          }
        }
  
        &__agreement {
  
          span {
            transition: all .25s ease-in-out;
  
            &:before {
              margin: 0 4px;
            }
          }
  
          input[type="checkbox"] + span {
            width: 16px;
            min-width: 16px;
            height: 16px;
            margin-right: 12px;
          }

          span.error-field {
            padding: 4px 10px;
            font-size: 10px;
            line-height: 10px;
            bottom: -18px;
          }
        }
      }
    }
  
    &__share {
      height: calc(100vh - 68px);
  
      &__info {
        width: 320px;
        padding-bottom: 192px;
        margin: 0 auto;

        &__complete {

          p {
            width: 300px;
            font-size: 18px;
            line-height: 31px;
            margin-bottom: 40px;
          }
        }

        &__want {
  
          .full-block {
  
            &__start {
  
              h4 {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 8px;
              }
  
              p {
                width: 300px;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 14px;
  
                &:last-of-type {
                  margin-bottom: 20px;
                }
              }
            }
  
            &__finish {
  
              p {
                font-size: 16px;
                line-height: 26px;
              }
            }
          }
        }
      }
  
      &__btns {
        flex-wrap: wrap;

        a.btn {
          width: 100%;
          height: 40px;
  
          &:first-of-type {
            height: 40px;
            margin-bottom: 20px;
          }

          span {
            font-size: 14px;
            line-height: 26px;
          }
        }
      }
    }
  }
  
  .social-share {
  
    &__item {
      height: 140px;
  
      &:before {
        width: 146px;
      }
  
      &:nth-of-type(1) {
  
        &:after {
          height: 106px;
          top: 20px; 
        }
      }
  
      img {
        width: 24px;
      }
    }
  
    span.share-title {
      font-size: 12px;
      line-height: 12px;
      top: -4px;
    }
  }
}

// tablet-horizontal
@media screen and (min-width: $tablet-horizontal_min) and (max-width: $tablet-horizontal_max) {
  
  .finishGuide {
  
    &:before {
      top: 59px;
    }
  
    &__bg {

      &__item {
  
        &:nth-of-type(1) {
          width: 720px;
          left: -20px;
          bottom: -10px;
        }
  
        &:nth-of-type(2) {
          width: 180px;
          left: 300px;
          bottom: -10px;
        }
  
        &:nth-of-type(3) {
          width: 146px;
          left: 412px;
        }
      }
    }
  
    &__arrow {
      width: 500px;
      height: 124px;
      bottom: 140px;
    }
  
    &__complete {
      width: 500px;
      margin-top: 160px;
      margin-left: calc((100% - 926px) / 2);
  
      &__item {

        &:nth-of-type(2) {
  
          h2 {
            font-size: 38px;
            line-height: 58px;
          }
        }
  
        h2 {
          margin-bottom: 16px;
        }
  
        p {
          width: 100%;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  
    &__data {
      width: 420px;
      height: calc(100vh - 60px);
      margin-top: 60px;
    }
  
    &__form {
      width: 320px;
      height: calc(100vh - 60px);
      padding-bottom: 60px;
  
      &__btn {

        button.btn__type-4 {
          height: 60px;

          &:after {
            display: none;
          }
        }
      }
  
      .field {
  
        &__item {
          
          &:not(:last-of-type) {
            margin-bottom: 56px;
          }
  
          &:first-of-type {
            margin-bottom: 100px;
          }
  
          input {
            font-size: 26px;
            line-height: 29px;
            padding-right: 0;
          }
        }

        &__agreement {

          span.error-field {
            padding: 4px 8px;
            font-size: 11px;
            line-height: 11px;
            bottom: -20px;
          }
        }
      }
    }
  
    &__share {
      height: calc(100vh - 60px);
  
      &__info {
        width: 320px;
        padding-bottom: 140px;

        &__complete {

          p {
            width: 100%;
            font-size: 20px;
            line-height: 34px;
          }
        }

        &__want {

          .full-block {

            &__start {
  
              h4 {
                font-size: 22px;
                line-height: 32px;
              }
  
              p {
                width: 100%;
                font-size: 18px;
                line-height: 32px;
                margin-bottom: 16px;
  
                a {
                  color: rgba(255,255,255,.5);
                  transition: color .25s ease-in-out;
  
                  &:hover {
                    color: rgba(255,255,255,.5);
                  }
                }
              }
            }
  
            &__finish {
  
              p {
                font-size: 18px;
                line-height: 32px;
              }
            }
          }
        }
      }
  
      &__btns {
        flex-wrap: wrap;

        a.btn {
          width: 100%;
          height: 46px;
  
          &:first-of-type {
            height: 46px;
            margin-bottom: 24px;
          }
  
          span {
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
    }
  }
  
  .social-share {
  
    &__item {
      height: 140px;
  
      &:before {
        width: 130px;
      }
  
      &:nth-of-type(1) {
  
        &:after {
          height: 108px;
          top: 20px; 
        }
      }
  
      &:hover {
        background: transparent;
  
        img {
          opacity: 1;
        }
      }
  
      img {
        opacity: 1;
      }
    }
  }
}

// tablet-portrait
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-portrait_max) {

  .finishGuide {
    flex-direction: column;
    justify-content: flex-start;
  
    &:before {
      top: 59px;
    }
  
    &__bg {
      display: none;
    }
  
    &__arrow {
      display: none;
    }
  
    &__complete {
      width: 720px;
      margin: 0 auto;
      margin-top: 140px;
  
      &__item {
        width: 100%;

        &._active {
          position: relative;
          top: auto;
          left: auto;
        }
      
        &:nth-of-type(2) {
  
          h2 {
            font-size: 42px;
            line-height: 60px;
            width: 660px;
          }
  
          p {
            width: 660px;
          }
        }
  
        h2 {
          margin-bottom: 24px;
        }
  
        p {
          width: 660px;
          font-size: 22px;
          line-height: 34px;
        }
      }
    }
  
    &__data {
      width: 100%;
      height: auto;
      margin-top: 0;
      border-left: none;
      overflow: hidden;

      &__item {
       
        &._active {
          position: relative;
          top: auto;
          left: auto;
        }
      }
    }
  
    &__form {
      width: 460px;
      height: auto;
      padding-top: 120px;
      padding-bottom: 60px;
  
      &__btn {
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;

        button.btn__type-4 {
          height: 60px;

          &:after {
            display: none;
          }
        }
      }
  
      .field {
  
        &__item {
          
          input {
            font-size: 28px;
            line-height: 34px;
          }
        }

        &__agreement {

          span {

            &:before {
              width: 8px;
              height: 13px;
              margin: 1px 6px;
            }
          }
        
          input[type="checkbox"] + span {
            width: 24px;
            min-width: 24px;
            height: 24px;
          }
        }
      }
    }
  
    &__share {
      height: auto;
      margin-top: 80px;
  
      &__info {
        width: 480px;
        padding-bottom: 140px;
        margin: 0 auto;

        &__complete {

          p {
            width: 480px;
            font-size: 22px;
            line-height: 34px;
            margin-bottom: 64px;
          }
        }

        &__want {

          .full-block {

            &__start {
  
              h4 {
                font-size: 22px;
                line-height: 32px;
              }
  
              p {
                width: 100%;
                font-size: 18px;
                line-height: 32px;
                margin-bottom: 16px;
  
                a {
                  color: rgba(255,255,255,.5);
                  transition: color .25s ease-in-out;
  
                  &:hover {
                    color: rgba(255,255,255,.5);
                  }
                }
              }
            }
  
            &__finish {
              width: 320px;
              margin: 0 auto;
  
              p {
                font-size: 18px;
                line-height: 32px;
              }
            }
          }
        }
      }
  
      &__btns {
        width: 100%;
        flex-wrap: wrap;
  
        a.btn {
          width: 220px;
          height: 60px;
  
          &:first-of-type {
            height: 60px;
          }
  
          span {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
  
      &__social {
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
      }
    }
  }
  
  .social-share {
  
    &__item {
      height: 140px;
  
      &:before {
        width: 280px;
      }
  
      &:nth-of-type(1) {
  
        &:after {
          height: 100px;
          top: 24px; 
        }
      }
  
      &:hover {
        background: transparent;
  
        img {
          opacity: 1;
        }
      }
  
      img {
        opacity: 1;
      }
    }
  
    span.share-title {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {

  .finishGuide {
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    &:before {
      display: none;
    }
  
    &__bg {
      display: none;
    }

    &__arrow {
      display: none;
    }
  
    &__complete {
      width: 100%;
      margin-top: 92px;
      margin-left: 0;
  
      &__item {
        padding-bottom: 32px;
        border-bottom: 1px solid rgba(255,255,255,.2);
        box-sizing: border-box;

        &._active {
          position: relative;
          top: auto;
          left: auto;
        }
  
        &:nth-of-type(2) {
  
          h2 {
            font-size: 22px;
            line-height: 33px;
            margin: 0;
          }
  
          p {
            display: none;
          }
        }
  
        h2 {
          padding-left: 5%;
          padding-right: 5%;
          margin-bottom: 4px;
          box-sizing: border-box;
        }
  
        p {
          width: 100%;
          padding-left: 5%;
          padding-right: 5%;
          font-size: 14px;
          line-height: 22px;
          color: rgba(255,255,255,.7);
          box-sizing: border-box;
        }
      }
    }
  
    &__data {
      width: 100%;
      height: auto;
      margin-top: 0;
      border-left: none;
      overflow: hidden;
  
      &__item {
        width: 100%;
        padding-top: 56px;
        padding-left: 5%;
        padding-right: 5%;
        opacity: 0;
        visibility: hidden;
        box-sizing: border-box;
        transition: all .25s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
  
        &._active {
          position: relative;
          top: auto;
          left: auto;
        }
      }
    }
  
    &__form {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 80px;
      margin: 0 auto;
      box-sizing: border-box;
      position: relative;
      z-index: 2;
  
      &__btn {
        width: 100%;
        background: #fff;
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        margin-top: 30px;
        margin-bottom: 40px;

        button.btn {
          height: 56px;

          span {
            font-size: 18px;
            line-height: 22px;
            color: rgba(7, 20, 48, .9);
          }

          &__type-4 {

            &:hover {

              span {
                color: rgba(7, 20, 48, .9);
              }
            }
          }
        }
      }
  
      .field {
  
        &__item {
          
          &:not(:last-of-type) {
            margin-bottom: 56px;
          }
  
          &:first-of-type {
            margin-bottom: 68px;
          }
  
          input {
            font-size: 18px;
            line-height: 21px;
            padding-right: 0;
          }
        }
  
        &__agreement {
          margin-bottom: 40px;
          
          span {
            font-size: 10px;
            line-height: 17px;
          }

          span.error-field {
            padding: 4px 6px;
            font-size: 11px;
            line-height: 15px;
            top: 46px;
            bottom: auto;
          }
        }
      }
    }
  
    &__share {
      height: auto;
  
      &__info {
        width: 100%;
        padding-bottom: 140px;
        margin: 0 auto;

        &__complete {

          p {
            width: 100%;
            font-size: 16px;
            line-height: 27px;
            word-break: inherit;
            margin-bottom: 40px;
          }
        } 

        &__want {

          .full-block {
  
            &__start {
  
              h4 {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 8px;
              }
  
              p {
                width: 100%;
                font-size: 16px;
                line-height: 27px;
                margin-bottom: 24px;
  
                span {
                  word-break: inherit;
                }
  
                a {
                  color: rgba(255,255,255,.5);
                  transition: color .25s ease-in-out;
  
                  &:hover {
                    color: rgba(255,255,255,.5);
                  }
                }
              }
            }
  
            &__finish {
  
              p {
                font-size: 16px;
                line-height: 27px;
              }
            }
          }
        }
      }
  
      &__btns {
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 40px;
  
        a.btn {
          width: 100%;
          height: 46px;
  
          &:first-of-type {
            height: 46px;
            margin-bottom: 22px;
          }
        }
      }
  
      &__social {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  
  .social-share {
  
    &__item {
      height: 92px;
  
      &:before {
        content: '';
        width: 60%;
      }
  
      &:nth-of-type(1) {
  
        &:before {
          left: 0;
        }
  
        &:after {
          height: 68px;
          top: 16px; 
        }
      }
  
      &:nth-of-type(2) {
  
        &:before {
          right: 0;
        }
      }
  
      &:hover {
        background: transparent;
  
        img {
          opacity: 1;
        }
      }
    }
  
    span.share-title {
      font-size: 12px;
      line-height: 20px;
      color: rgba(255,255,255,.6);
      top: -8px;
    }
  }
}

@media screen and (min-width: $mobile_min) and (max-width: 335px) {

  .finishGuide__form .field__agreement span.error-field {
    top: 60px;
  }
}
