// @mixin fontFace($family, $src, $family-slug: 'Regular', $style: normal, $weight: normal) {
//   @font-face {
//     font-family: $family;
//     font-display: auto;
//     src: local($family + ' ' + $family-slug), local($family + '-' + $family-slug), url("#{$src}.eot");
//     src: local($family + ' ' + $family-slug), local($family + '-' + $family-slug), url("#{$src}.eot?#iefix") format('embedded-opentype'), url("#{$src}.woff") format('woff'), url("#{$src}.ttf") format('truetype'), url("#{$src}.svg##{$family}") format('svg');
//     font-style: $style;
//     font-weight: $weight;
//   }
// }

@mixin fontFace(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff ttf svg otf
) {
  $src: null;

  $extmods: (
    eot: '?iefix',
    svg: '#' + str-replace($name, ' ', '_')
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + '.' + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}
