@import '../../../styles/variables';

.trainings {
  width: 100%;
  height: 100vh;
  padding-top: 160px;
  background: linear-gradient(180deg, rgba(7, 20, 48, 0.8) 33.18%, rgba(223, 0, 49, 0.8) 170.74%);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &._show {

    &:before {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    opacity: 0;
    transform: translateY(-80px);
    background-color: rgba(255,255,255,.15);
    position: absolute;
    left: 0;
    top: 78px;
    transition: all .35s ease-in-out;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  &__loader {
    display: none;
  }

  &__main {
    width: 968px;
    margin: 0 auto;

    &__video {
      width: 100%;
      height: 544px;
      margin-bottom: 48px;
      position: relative;

      &__preview {
        width: 100%;
        height: 544px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        transition: all .3s ease-in-out;

        &._hidden {
          opacity: 0;
          visibility: hidden;
        }

        button {
          width: 100px;
          height: 100px;
          background-color: #fff;
          border-radius: 50%;
          border: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 15;
          cursor: pointer;

          &:hover {

            &:after {
              transform: translate(-40%,-50%) scale(1.2);
            }
          }

          &:after {
            content: '';
            width: 40px;
            height: 40px;
            background-image: url('../../../static/images/icons/icon-play-red.svg');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 52%;
            transform: translate(-40%,-50%);
            transition: all .25s ease-in-out;
          }
        }
      }

      &__frame {
        width: 100%;
        height: 544px;

        iframe, video {
          width: 100%;
          height: 544px;
          z-index: 5;
        }
      }

      &__error {
        width: 100%;
        height: 544px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(255,255,255,.15);
        box-sizing: border-box;
      }
    }

    &__desc {
      padding-bottom: 32px;
      border-bottom: 1px solid rgba(255,255,255,.15);
      box-sizing: border-box;

      h3 {
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        color: #FFFFFF;
        margin: 0;
        margin-bottom: 24px;
      }

      p {
        font-weight: 400;
        font-size: 22px;
        line-height: 22px;
        color: rgba(255,255,255,.7);
        margin: 0;

        &.author-name {
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 12px;
        }

        &.training-desc {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  &__list {
    width: 652px;

    &__header {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 32px;
      padding-right: 24px;
      background: rgba(255,255,255,.2);
      // box-shadow: 0px 12px 20px #091D4A;
      box-sizing: border-box;

      &__title {

        p {
          font-weight: 500;
          font-size: 24px;
          line-height: 41px;
          color: #fff;
          text-transform: uppercase;
          margin: 0;
        }
      }

      &__attention {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        img {
          width: 20px;
          margin-right: 8px;
          opacity: .7;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          text-transform: uppercase;
          color: rgba(255,255,255,.7);
          margin: 0;
        }
      }
    }

    &__body {
      height: 660px;
      border-left: 7px solid rgba(255,255,255,.1);
      border-bottom: 7px solid rgba(255,255,255,.1);
      box-sizing: border-box;

      &._right {
        border-right: 7px solid rgba(255,255,255,.1);
      }

      .scrollarea {
        height: 653px;
        opacity: 1;

        &:hover {

          .scrollbar-container {
            opacity: 1;
          }
        }

        .scrollbar-container {
          width: 7px;
          opacity: 1;
          background: rgba(255,255,255,.1);

          &:hover {
            background: transparent;
            opacity: 1 !important;
          }
        }

        .scrollbar-container.vertical .scrollbar {
          width: 6px;
          background: #E40031;
          border-radius: 35px;
        }
      }

      // .ps__rail-y {
      //   width: 7px;
      //   background-color: rgba(255,255,255,.1);
      //   opacity: 1;
      //   right: 0;
      //   z-index: 10;

      //   .ps__thumb-y {
      //     width: 6px;
      //     background: #E40031;
      //     border-radius: 35px;
      //     right: 0px;
      //   }
      // }

      // .ps .ps__rail-y:hover {
      //   background: rgba(255,255,255,.1);
      //   opacity: 1;
      // }

      // .ps__rail-y:hover > .ps__thumb-y {
      //   width: 7px;
      //   background: #E40031;
      // }

      // .ps:hover > .ps__rail-y,
      // .ps--focus > .ps__rail-y,
      // .ps--scrolling-y > .ps__rail-y {
      //   opacity: 1;
      // }

      // .ps--active-y > .ps__rail-y {
      //   background: rgba(255,255,255,.1);
      // }
    }
  }

  &__locking {
    width: 100%;
    height: calc(100vh - 240px);
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #fff;
      margin: 0;
    }
  }
}

.videos {

  &__item {
    width: 100%;
    height: 138px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 0 12px 16px;
    margin-bottom: 12px;
    box-sizing: border-box;
    transition: all .25s ease-in-out;
    position: relative;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      background-image: url('../../../static/images/icons/icon-play-white.svg');
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0;
      visibility: hidden;
      transition: all .25s ease-in-out;
      position: relative;
      top: 50%;
      left: -4px;
      transform: translateY(-50%);
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      background: rgba(255,255,255,.05);

      .videos__info h3 {
        color: rgba(255,255,255,1);
      }
    }

    &._active {

      &:before {
        opacity: 1;
        visibility: visible;
      }

      .videos__info h3 {
        color: rgba(255,255,255,1);
      }
    }
  }

  p.title-type {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(255,255,255,.3);
    padding: 12px 16px 8px 24px;
    display: none;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      display: block;
    }
  }

  button {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &__preview {
    width: 202px;
    height: 114px;
    margin-right: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    &:before {
      width: 33px;
      height: 33px;
      background: #fff;
      border-radius: 50%;
      overflow: hidden;
    }

    &:after {
      width: 8px;
      height: 10px;
      background-image: url('../../../static/images/icons/icon-play-red.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__info {
    width: calc(100% - 234px);
    padding-top: 12px;
    padding-right: 10px;
    box-sizing: border-box;

    h3 {
      font-weight: 800;
      font-size: 18px;
      line-height: 27px;
      text-transform: uppercase;
      color: rgba(255,255,255,.7);
      margin: 0;
      margin-bottom: 8px;
      transition: color .25s ease-in-out;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-transform: lowercase;
      color: rgba(255,255,255,.7);
      margin: 0;

      &.author-name {
        text-transform: inherit;
      }
    }
  }
}

.videos-mobile {
  display: none;
}

// desktop
@media screen and (min-width: $desktop1_min) and (max-width: $desktop1_max) {

  .trainings {
    padding-top: 110px;
  
    &:before {
      top: 66px;
    }
  
    &__main {
      width: 740px;
  
      &__video {
        height: 400px;
        margin-bottom: 32px;
  
        &__preview {
          height: 400px;
  
          button {
            width: 90px;
            height: 90px; 
          }
        }
  
        &__frame {
          height: 400px;
  
          iframe, video {
            height: 400px;
          }
        }
  
        &__error {
          height: 400px;
        }
      }
  
      &__desc {
  
        h3 {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 16px;
        }
  
        p {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  
    &__list {
      width: 500px;
  
      &__header {
        height: 62px;
        padding-left: 24px;
        padding-right: 16px;
  
        &__title {
  
          p {
            font-size: 20px;
            line-height: 34px;
          }
        }
  
        &__attention {
  
          img {
            width: 18px;
          }
  
          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
  
      &__body {
        height: 468px;

        .scrollarea {
          height: 461px;
        }
      }
    }
  }
  
  .videos {

    p.title-type {
      padding: 12px 16px 8px 20px;
    }
  
    &__item {
      height: 114px;
      padding: 8px 16px  8px 10px;
      margin-bottom: 6px;
      
      &:before {
        left: -2px;
      }
    }
  
    &__preview {
      width: 164px;
      height: 92px;
      margin-right: 16px;
    }
  
    &__info {
      width: calc(100% - 180px);
      padding-top: 10px;
  
      h3 {
        font-size: 16px;
        line-height: 24px;
      }
  
      p {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

@media screen and (min-width: $desktop2_min) and (max-width: $desktop2_max) {

  .trainings {
    padding-top: 100px;
  
    &:before {
      top: 66px;
    }
  
    &__main {
      width: 648px;
  
      &__video {
        height: 360px;
        margin-bottom: 24px;
  
        &__preview {
          height: 360px;
  
          button {
            width: 90px;
            height: 90px; 
          }
        }
  
        &__frame {
          height: 360px;
  
          iframe, video {
            height: 360px;
          }
        }
  
        &__error {
          height: 360px;
        }
      }
  
      &__desc {
        padding-bottom: 24px;
  
        h3 {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 16px;
        }
  
        p {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  
    &__list {
      width: 460px;
  
      &__header {
        height: 48px;
        padding-left: 24px;
        padding-right: 16px;
  
        &__title {
  
          p {
            font-size: 18px;
            line-height: 24px;
          }
        }
  
        &__attention {
  
          img {
            width: 18px;
          }
  
          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
  
      &__body {
        height: 428px;
        border-left: 5px solid rgba(255,255,255,.1);
        border-bottom: 5px solid rgba(255,255,255,.1);

        &._right {
          border-right: 5px solid rgba(255,255,255,.1);
        }

        .scrollarea {
          height: 423px;
  
          .scrollbar-container {
            width: 5px;
          }
  
          .scrollbar-container.vertical .scrollbar {
            width: 4px;
          }
        }

        // .ps__rail-y {
        //   width: 5px;
  
        //   .ps__thumb-y {
        //     width: 4px;
        //   }
        // }
      }
    }
  }
  
  .videos {
  
    p.title-type {
      padding: 12px 16px 8px 18px;
    }

    &__item {
      height: 108px;
      padding: 8px 16px 8px 8px;
      margin-bottom: 4px;

      &:before {
        left: -2px;
      }
    }
  
    &__preview {
      width: 164px;
      height: 92px;
      margin-right: 12px;
    }
  
    &__info {
      width: calc(100% - 180px);
      padding-top: 10px;
  
      h3 {
        font-size: 16px;
        line-height: 24px;
      }
  
      p {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

@media screen and (min-width: $desktop2_min) and (max-width: $desktop1_max) and (max-height: 650px) {
  .trainings {
    height: 100%;
    overflow: auto;
  }
}

// tablet-horizontal
@media screen and (min-width: $tablet-horizontal_min) and (max-width: $tablet-horizontal_max) {
  
  .trainings {
    padding-top: 120px;
  
    &:before {
      top: 59px;
    }
  
    &__main {
      width: 480px;
  
      &__video {
        height: 300px;
        margin-bottom: 32px;
  
        &__preview {
          height: 300px;
  
          button {
            width: 80px;
            height: 80px;
  
            &:hover {
  
              &:after {
                transform: translate(-40%,-50%) scale(1);
              }
            }
  
            &:after {
              width: 30px;
              height: 30px;
            }
          }
        }
  
        &__frame {
          height: 300px;
  
          iframe, video {
            height: 300px;
          }
        }
  
        &__error {
          height: 300px;

          p {
            width: 340px;
            text-align: center;
          }
        }
      }
  
      &__desc {
        padding-bottom: 24px;
  
        h3 {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 20px;
        }
  
        p {
          font-size: 18px;
          line-height: 18px;

          &.author-name {
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 10px;
          }
  
          &.training-desc {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  
    &__list {
      width: 408px;
  
      &__header {
        height: 40px;
        padding-left: 24px;
        padding-right: 16px;
  
        &__title {
  
          p {
            font-size: 18px;
            line-height: 24px;
          }
        }
  
        &__attention {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
  
          img {
            width: 14px;
          }
  
          p {
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
  
      &__body {
        height: 520px;
        border-left: 5px solid rgba(255,255,255,.1);
        border-bottom: 5px solid rgba(255,255,255,.1);

        &._right {
          border-right: 5px solid rgba(255,255,255,.1);
        }

        .scrollarea {
          height: 515px;
  
          .scrollbar-container {
            width: 5px;
          }
  
          .scrollbar-container.vertical .scrollbar {
            width: 4px;
          }
        }
  
        // .ps__rail-y {
        //   width: 5px;
  
        //   .ps__thumb-y {
        //     width: 5px;
        //   }
        // }
      }
    }
  }
  
  .videos {

    p.title-type {
      padding: 12px 16px 8px 18px;
    }
  
    &__item {
      height: auto;
      align-items: center;
      padding: 8px 0 8px 8px;
      margin-bottom: 4px;
  
      &:before {
        left: -2px;
      }
  
      &:hover {
        background: rgba(255,255,255,0);
  
        .videos__info h3 {
          color: rgba(255,255,255,.7);
        }
      }
    }
  
    &__preview {
      width: 148px;
      height: 100px;
      margin-right: 12px;
  
      &:before {
        width: 28px;
        height: 28px;
      }
  
      &:after {
        width: 7px;
        height: 9px;
      }
    }
  
    &__info {
      width: calc(100% - 160px);
      padding-top: 0;
  
      h3 {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 4px;
      }
  
      p {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

// tablet-portrait
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-portrait_max) {

  .trainings {
    height: 100%;
    min-height: 100vh;
    padding-top: 60px;
  
    &:before {
      top: 59px;
    }
  
    .container {
      width: 100%;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: column;
    }
  
    &__main {
      width: 100%;
      margin-bottom: 40px;
      padding-top: 60px;

      &__video {
        width: 100%;
        height: 430px;
        margin-bottom: 30px;
  
        &__preview {
          height: 430px;
          
          button {
            width: 90px;
            height: 90px;
  
            &:hover {
  
              &:after {
                transform: translate(-40%,-50%) scale(1);
              }
            }
  
            &:after {
              width: 30px;
              height: 30px;
            }
          }
        }
  
        &__frame {
          height: 430px;
  
          iframe, video {
            height: 430px;
          }
        }
  
        &__error {
          height: 430px;
        }
      }
  
      &__desc {
        width: 720px;
        margin: 0 auto;
        padding-bottom: 0;
        border-bottom: none;
  
        h3 {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 24px;
        }
  
        p {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  
    &__list {
      width: 100%;
  
      &__header {
        height: 56px;
        padding-left: 24px;
        padding-right: 24px;
  
        &__title {
  
          p {
            font-weight: 500;
            font-size: 24px;
            line-height: 41px;
            color: #fff;
            text-transform: uppercase;
            margin: 0;
          }
        }
  
        &__attention {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
  
          img {
            width: 20px;
            margin-right: 8px;
            opacity: .7;
          }
  
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba(255,255,255,.7);
            margin: 0;
          }
        }
      }
  
      &__body {
        height: auto;
        border-left: none;
        border-bottom: none;

        &._right {
          border-right: none;
        }

        .scrollarea {
          height: auto;
  
          .scrollbar-container {
            width: 5px;
          }
  
          .scrollbar-container.vertical .scrollbar {
            width: 4px;
          }
        }

        .scrollbar-container {
          padding-top: 10px;
        }
      }
    }

    &__locking {
      height: calc(100vh - 58px);
  
      p {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  
  .videos {
  
    &__item {
      width: 100%;
      height: 120px;
      padding: 0 8px 0 16px;
      margin-bottom: 12px;
  
      &:hover {
        background: rgba(255,255,255,0);
  
        .videos__info h3 {
          color: rgba(255,255,255,1);
        }
      }

      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    p.title-type {
      padding: 12px 16px 8px 28px;
    }
  
    &__preview {
      width: 202px;
      height: 120px;
      margin-right: 32px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: relative;
  
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
  
      &:before {
        width: 33px;
        height: 33px;
        background: #fff;
        border-radius: 50%;
        overflow: hidden;
      }
  
      &:after {
        width: 8px;
        height: 10px;
        background-image: url('../../../static/images/icons/icon-play-red.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  
    &__info {
      width: calc(100% - 234px);
      padding-top: 12px;
      padding-right: 10px;
  
      h3 {
        font-size: 18px;
        line-height: 27px;
        color: rgba(255,255,255,1);
        margin-bottom: 8px;
      }
  
      p {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {

  .trainings {
    width: 100%;
    background: transparent;
    height: auto;
    padding-top: 60px;
  
    &:before {
      display: none;
    }
  
    .container {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      z-index: 5;
    }

    &__loader {
      width: 100%;
      height: 100vh;
      display: block;
      background: rgba(7, 20, 48, .8);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;

      img {
        width: 50px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 60;
        transform: translate(-50%,-50%);
      }
    }
  
    &__main {
      width: 100%;
      margin-bottom: 40px;
      padding-top: 60px;
  
      &__video {
        width: 100%;
        height: 180px;
        margin-bottom: 24px;
  
        &__preview {
          height: 180px;
  
          button {
            width: 40px;
            height: 40px;
  
            &:hover {
  
              &:after {
                transform: translate(-40%,-50%) scale(1);
              }
            }
  
            &:after {
              width: 16px;
              height: 16px;
              left: 54%;
            }
          }
        }
  
        &__frame {
          height: 180px;
  
          iframe, video {
            height: 180px;
          }
        }
  
        &__error {
          height: 180px;

          p {
            font-size: 16px;
            line-height: 22px;
            text-align: center;
          }
        }
      }
  
      &__desc {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 0;
        border-bottom: none;
        box-sizing: border-box;
  
        h3 {
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 10px;
        }
  
        p {
          font-size: 14px;
          line-height: 14px;

          &.author-name {
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 10px;
          }
  
          &.training-desc {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  
    &__list {
      width: 100%;
  
      &__header {
        width: 100%;
        height: 40px;
        padding-left: 5%;
        padding-right: 5%;
        box-sizing: border-box;
  
        &__title {
  
          p {
            font-size: 12px;
            line-height: 20px;
          }
        }
  
        &__attention {
          display: none;
        }
      }
  
      &__body {
        height: auto;
        border-left: none;
        border-bottom: none;

        &._right {
          border-right: none;
        }

        .scrollarea {
          height: auto;
  
          .scrollbar-container {
            width: 5px;
          }
  
          .scrollbar-container.vertical .scrollbar {
            width: 4px;
          }
        }

        .scrollbar-container {
          padding-top: 10px;
        }
      }
    }

    &__locking {
      height: calc(100vh - 80px);
      padding-right: 20px;
      padding-left: 20px;
      box-sizing: border-box;
  
      p {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  .videos {

    &__item {
      height: auto;
      flex-direction: column;
      padding: 0;
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 32px;
      }
  
      &:before {
        display: none;
      }
  
      &:hover {
        background: rgba(255,255,255,0);
  
        .videos__info h3 {
          color: rgba(255,255,255,1);
        }
      }
  
      &._active {
  
        &:before {
          opacity: 1;
          visibility: visible;
        }
  
        .videos__info h3 {
          color: rgba(255,255,255,1);
        }
      }
    }
  
    &__preview {
      width: 100%;
      height: 180px;
      margin-right: 0;
      margin-bottom: 16px;
  
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
  
      &:before {
        width: 33px;
        height: 33px;
        background: #fff;
        border-radius: 50%;
        overflow: hidden;
      }
  
      &:after {
        width: 8px;
        height: 10px;
        background-image: url('../../../static/images/icons/icon-play-red.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  
    &__info {
      width: 100%;
      padding: 0 5%;
  
      h3 {
        font-size: 14px;
        line-height: 21px;
        color: rgba(255,255,255,1);
      }
  
      p {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .videos-mobile {
    display: flex;
    flex-direction: column;

    p.title-type {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: rgba(255,255,255,.3);
      padding: 12px 16px 8px 5%;
      display: none;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        display: block;
      }
    }

    &__item {
      width: 100%;
      margin-bottom: 32px;
      position: relative;

      &._hidden {

        .videos-mobile__main__preview {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &__main {
      width: 100%;
      height: 180px;
      margin-bottom: 16px;
      position: relative;

      &__preview {
        width: 100%;
        height: 180px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        &:before {
          content: '';
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;
          border: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }

        &:after {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url('../../../static/images/icons/icon-play-red.svg');
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 50%;
          left: 50.5%;
          z-index: 3;
          transform: translate(-40%, -50%);
          transition: all .25s ease-in-out;
        }

        p.title-type {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: rgba(255,255,255,.3);
          padding: 12px 16px 8px 16px;
          display: none;

          &:nth-of-type(1),
          &:nth-of-type(2) {
            display: block;
          }
        }

        button {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
        }
      }

      &__video {
        width: 100%;
        height: 180px;

        iframe, video {
          width: 100%;
          height: 180px;
        }
      }
    }

    &__info {
      padding: 0 5%;
      box-sizing: border-box;

      h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        color: #fff;
        margin: 0;
        margin-bottom: 8px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba(255,255,255,.7);
        margin: 0;

        &.author-name {
          margin-bottom: 10px;
        }

        &.training-desc {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}