@import '../../../styles/variables';

.startScreen {
  background: #071430;
}

.startGuide {
  width: 100%;
  height: 100vh;
  padding-top: 380px;
  background: linear-gradient(180deg, rgba(7, 20, 48, 0.8) 33.18%, rgba(223, 0, 49, 0.8) 170.74%);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &._show {

    &:before {
      opacity: 1;
      transform: translateY(0);
    }

    &:after {
      opacity: 1;
      transform: translateY(0);
    }

    .startGuide__decor__layer {
      opacity: 1;
    }

    .startGuide__decor__text {
      opacity: 1;
    }

    .startGuide__img img.person {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    .startGuide__text {

      h1 {
        opacity: 1;
        transform: translateY(0);
      }

      p {
        opacity: 1;
        transform: translateY(0);
      }

      a.btn {
        opacity: 1;
        transform: translateY(0);
      }
    }

    p.footnote,
    p.footnote-mobile {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    opacity: 0;
    transform: translateY(-80px);
    background-color: rgba(255,255,255,.15);
    position: absolute;
    left: 0;
    top: 80px;
    transition: all .4s ease-in-out;
  }

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,.15);
    opacity: 0;
    transform: translateY(50px);
    position: absolute;
    left: 0;
    bottom: 80px;
    transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  }

  .container {
    height: 100%;
  }

  &__decor {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;

    &__layer {
      width: 1192px;
      height: 1192px;
      background-image: url('../../../static/images/startScreen/layer_img.png');
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0;
      position: absolute;
      right: -132px;
      bottom: 74px;
      z-index: 1;
      transition: opacity .4s .6s linear;
    }

    &__text {
      width: 100%;
      overflow: hidden;
      opacity: 0;
      transition: opacity .4s .8s linear;
      position: absolute;
      top: 292px;
      left: 0;
      z-index: 3;
      opacity: 0;

      span.decor-text {
        box-sizing: content-box;
        white-space: nowrap;
        font-family: 'InputMono';
        font-size: 128px;
        line-height: 141px;
        font-weight: 800;
        letter-spacing: -3px;
        text-transform: uppercase;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba(255,255,255,.1);
      }
    }
  }

  &__img {
    width: 954px;
    height: 954px;
    position: absolute;
    right: -40px;
    bottom: 0;
    z-index: 10;

    img.person {
      width: 522px;
      height: 920px;
      opacity: 0;
      transform: translate(-50%, 50px);
      transition: all .5s .8s linear;
      position: absolute;
      left: 47%;
      bottom: 0;
      z-index: 2;
    }


    img.circle {
      width: 100%;
      height: 954px;
      opacity: .85;
      position: absolute;
      left: 0;
      bottom: -96px;
      z-index: 1;
    }
  }

  &__text {
    width: 890px;
    position: relative;
    z-index: 15;

    h1 {
      margin-bottom: 8px;
      opacity: 0;
      transform: translateY(50px);
      transition: all .3s .3s ease-in-out;
    }

    p {
      width: 100%;
      font-family: 'InputMono';
      font-size: 20px;
      line-height: 38px;
      font-weight: 300;
      color: rgba(255,255,255,.9);
      margin: 0;
      margin-bottom: 48px; 
      opacity: 0;
      transform: translateY(50px);
      transition: all .3s .4s ease-in-out;
    }

    img {
      width: 18px;
      display: inline-block;
      vertical-align: baseline;

      &.mobile-img {
        display: none;
      }
    }

    a.btn {
      width: 402px;
      margin-top: 150px;
      opacity: 0;
      transform: translateY(50px);
      transition: all .3s .5s ease-in-out;
    }
  }

  &__mobile {
    display: none;
  }

  p.footnote,
  p.footnote-mobile {
    font-family: 'InputMono';
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;
    text-transform: uppercase;
    color: #fff;
    opacity: 0;
    transition: all .5s 1.1s linear;
    position: absolute;
    right: 0;
    bottom: 28px;
    z-index: 10;

    a {
      color: #fff;
    }
  }
}

// desktop
@media screen and (min-width: $desktop1_min) and (max-width: $desktop1_max) {
  
  .startGuide {
    padding-top: 200px;

    &:before {
      top: 68px;
    }
  
    &:after {
      bottom: 56px;
    }
  
    &__decor {
  
      &__layer {
        width: 1100px;
        background-position: left bottom;
      }
    }

    &__img {
      width: 660px;
      height: 660px;
      position: absolute;
      right: -40px;
      bottom: 0;
      z-index: 10;
  
      img.person {
        width: 350px;
        height: auto;
      }
  
      img.circle {
        width: 100%;
        height: 660px;
      }
    }
  
    &__text {
      width: 680px;
  
      h1 {
        margin-bottom: 24px;
      }
  
      p {
        width: 100%;
        font-size: 16px;
        line-height: 27px;
        margin-bottom: 40px;
      }
  
      a.btn {
        width: 308px;
        margin-top: 100px;
      }
    }

    p.footnote {
      font-size: 12px;
      bottom: 18px;
    }
  }
}

@media screen and (min-width: $desktop2_min) and (max-width: $desktop2_max) {

  .startGuide {
    padding-top: 200px;

    &:before {
      top: 68px;
    }
  
    &:after {
      bottom: 56px;
    }

  
    &__decor {
  
      &__layer {
        width: 990px;
        background-position: left bottom;
      }
    }
  
    
    &__img {
      width: 640px;
      height: 640px;
      right: -30px;
  
      img.person {
        width: 320px;
        height: auto;
      }
  
      img.circle {
        width: 100%;
        height: 640px;
      }
    }
  
    &__text {
      width: 520px;
  
      p {
        width: 320px;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 40px;
      }
  
      a.btn {
        width: 308px;
        margin-top: 100px;
      }
    }

    p.footnote {
      bottom: 18px;
      font-size: 12px;
    }
  }
}

// tablet-horizontal
@media screen and (min-width: $tablet-horizontal_min) and (max-width: $tablet-horizontal_max) {
  
  .startGuide {
    padding-top: 280px;
  
    &:before {
      transform: translateY(-60px);
      top: 60px;
    }
  
    &:after {
      transform: translateY(60px);
      bottom: 60px;
    }
  
    &__decor {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
  
      &__layer {
        width: 900px;
        height: 900px;
      }
  
      &__text {
        top: 198px;
  
        span.decor-text {
          font-size: 120px;
          line-height: 131px;
          font-weight: 800;
          letter-spacing: -2.8px;
        }
      }
    }
  
    &__img {
      width: 600px;
      height: 600px;
      right: -40px;
  
      img.person {
        width: 320px;
        height: auto;
      }
  
      img.circle {
        height: 560px;
        bottom: -46px;
      }
    }
  
    &__text {
      width: 460px;
  
      p {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px; 
      }
  
      img {
        width: 16px;
      }
  
      a.btn {
        width: 280px;
        margin-top: 80px;
      }
    }
  
    p.footnote,
    p.footnote-mobile {
      bottom: 18px;
    }
  }
}

// tablet-portrait
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-portrait_max) {

  .startGuide {
    height: 100vh;
    padding-top: 180px;
  
    &:before {
      transform: translateY(-60px);
      top: 60px;
    }
  
    &:after {
      transform: translateY(60px);
      bottom: 60px;
    }
  
    &__decor {

      &__layer {
        width: 1000px;
        height: 1000px;
      }
  
      &__text {
        top: 342px;
  
        span.decor-text {
          font-size: 100px;
          line-height: 110px;
        }
      }
    }
  
    &__img {
      width: 640px;
      height: 640px;
  
      img.person {
        width: 360px;
        height: auto;
      }
  
      img.circle {
        height: 640px;
        bottom: -96px;
      }
    }
  
    &__text {
      width: 100%;
  
      h1 {
        margin-bottom: 16px;
      }
  
      p {
        width: 400px;
        font-size: 22px;
        line-height: 40px;
        margin-bottom: 40px; 
      }
  
      img {
        width: 16px;
      }
  
      a.btn {
        width: 280px;
        margin-top: 80px;
      }
    }
  
    p.footnote,
    p.footnote-mobile {
      bottom: 18px;
    }
  }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {

  .startGuide {
    height: auto;
    min-height: 100vh;
    padding-top: 100px;
    background: linear-gradient(180deg, rgba(7, 20, 48, 0.8) 33.18%, rgba(223, 0, 49, 0.8) 170.74%);

    &._show {

      .startGuide__mobile__img {
      
        img.person-mobile {
          opacity: 1;
          transform: translate(-10px, 0px);
        }
      }

      a.mobile-btn {
        opacity: 1;
        transform: translate(-50%, 0px);
      }
    }

    &:before {
      display: none;
    }
  
    &:after {
      display: none;
    }
  
    .container {
      height: 100%;
    }
  
    &__decor {
      width: 100%;
      height: 100%;
      top: 0;
      bottom: auto;
  
      &__layer {
        width: 100%;
        height: 653px;
        background-image: url('../../../static/images/startScreen/layer_img_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        right: auto;
        bottom: auto;
        transition-delay: .6s;
      }

      &__text {
        display: none;
      }
    }
  
    &__img {
      display: none;
    }
  
    &__text {
      width: 100%;
      padding-bottom: 0;
  
      h1 {
        margin-bottom: 24px;
        transition: all .5s .35s ease-in-out;
      }
  
      p {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;
        transition: all .5s .45s ease-in-out;
      }
  
      img {
        width: 16px;
      }
  
      a.btn {
        display: none;
      }
    }

    &__mobile {
      width: 100%;
      display: block;
      position: relative;

      &__img {
        position: relative;
        padding-top: 10px;

        img.person-mobile {
          width: 210px;
          display: block;
          margin: 0 auto;
          transform: translate(-10px, 50px);
          opacity: 0;
          transition: all .5s .6s ease-in-out;
          position: relative;
          z-index: 10;
        }

        img.circle-mobile {
          width: 280px;
          height: 280px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 5;
        }
      }

      a.mobile-btn {
        width: 90%;
        position: fixed;
        left: 50%;
        bottom: 60px;
        box-sizing: border-box;
        transform: translate(-50%, 50px);
        opacity: 0;
        transition: all .5s .7s ease-in-out;
        z-index: 10;
      }

      p.footnote-mobile {
        font-size: 11px;
        right: auto;
        left: 0;
        bottom: 20px;
        box-sizing: border-box;
      }

      p.footnote-mobile {
        display: block;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    p.footnote {
      font-size: 11px;
      right: auto;
      left: 0;
      bottom: 20px;
      padding-left: 5%;
      padding-right: 5%;
      box-sizing: border-box;
    }

    p.footnote {
      display: none;
    }
  }
}

@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) and (min-height: 660px) {

  .startGuide {

    &__mobile {
      position: fixed;
      bottom: 0;
      height: 440px;
      z-index: 1;

      &__img {
        
        img.person-mobile {
          width: 246px;
        }

        img.circle-mobile {
          width: 320px;
          height: 320px;
        }
      }
    }
  }
}
