@import '../../../styles/variables';

.trainingScreen {
  width: 100%;
  height: 100vh;
  background-color: #071430;
  position: relative;
  overflow: hidden;
}

.training {
  padding-top: 140px;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    opacity: 0;
    transform: translateY(-80px);
    background-color: rgba(255,255,255,.15);
    position: absolute;
    left: 0;
    top: 78px;
    transition: all .35s ease-in-out;
  }

  &._show {

    &:before {
      opacity: 1;
      transform: translateY(0);
    }

    .training__item {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__content {
    width: 1068px;
    margin: 0 auto;
  }

  &__item {
    width: 100%;
    height: 600px;
    opacity: 0;
    transform: translateY(60px);
    transition: all .5s .3s ease-in-out;
    position: relative;

    &__preview {
      width: 100%;
      height: 600px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      opacity: 1;
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      transition: all .3s ease-in-out;

      &._hidden {
        opacity: 0;
        visibility: hidden;
      }

      button {
        width: 100px;
        height: 100px;
        background-color: #fff;
        border-radius: 50%;
        border: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 15;
        cursor: pointer;

        &:hover {

          &:after {
            transform: translate(-50%,-50%) scale(1.2);
          }
        }

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 0 15px 25px;
          border-color: transparent transparent transparent #007bff;
          transition: transform .3s ease-in-out;
          position: absolute;
          top: 50%;
          left: 52%;
          transform: translate(-50%,-50%);
        }
      }
    }

    &__video {
      width: 100%;
      height: 600px;
      margin-bottom: 20px;
      position: relative;
      z-index: 5;

      iframe, video {
        width: 100%;
        height: 600px;
      }
    }

    &__title {
      text-align: center;

      p {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        color: #fff;
        margin: 0;
      }
    }
  }
}

// desktop
@media screen and (min-width: $desktop2_min) and (max-width: $desktop1_max) {
  
  .training {
    padding-top: 120px;
  
    &:before {
      top: 67px;
    }
  
    &__content {
      width: 854px;
    }
  
    &__item {
      height: 480px;
  
      &__preview {
        height: 480px;
      }
  
      &__video {
        height: 480px;
  
        iframe, video {
          height: 480px;
        }
      }
    }
  }
}

// tablet-horizontal
@media screen and (min-width: $tablet-horizontal_min) and (max-width: $tablet-horizontal_max) {
  
  .training {
    padding-top: 140px;
  
    &:before {
      top: 59px;
    }
  
    &__content {
      width: 748px;
    }
  
    &__item {
      height: 420px;
  
      &__preview {
        height: 420px;

        button {

          &:hover {

            &:after {
              transform: translate(-50%,-50%) scale(1);
            }
          }
        }
      }
  
      &__video {
        height: 420px;
  
        iframe, video {
          height: 420px;
        }
      }
    }
  }
}

// tablet-portrait
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-portrait_max) {

  .training {
    padding-top: 160px;
  
    &:before {
      top: 59px;
    }
  
    &__content {
      width: 640px;
    }
  
    &__item {
      height: 360px;
  
      &__preview {
        height: 360px;

        button {

          &:hover {

            &:after {
              transform: translate(-50%,-50%) scale(1);
            }
          }
        }
      }
  
      &__video {
        height: 360px;
  
        iframe, video {
          height: 360px;
        }
      }
    }
  }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {
  
  .training {
    padding-top: 100px;
  
    &:before {
     display: none;
    }
  
    &__content {
      width: 90%;
    }
  
    &__item {
      height: 240px;
  
      &__preview {
        height: 240px;

        button {
          width: 60px;
          height: 60px;

          &:after {
            border-width: 10px 0 10px 20px;
          }

          &:hover {

            &:after {
              transform: translate(-50%,-50%) scale(1);
            }
          }
        }
      }
  
      &__video {
        height: 240px;
  
        iframe, video {
          height: 240px;
        }
      }

      &__title {

        p {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}