@import '../../../styles/variables';

.step {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;

  &._activeStep {
    opacity: 1;
    visibility: visible;

    .step__question {

      h2, p {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .step__info {
      opacity: 1;
    }

    .step__answers {
      opacity: 1;
    }

    .step__question {
      opacity: 1;
    }

    .step__arrow {
      opacity: 1;
      transform: translateX(0);
    }

    .step__next {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    background: linear-gradient(180deg, rgba(7, 20, 48, 0.368) 0%, rgba(15, 65, 180, 0) 100%);
    box-sizing: border-box;
    position: relative;
    z-index: 2;

    &._two {
      justify-content: space-between;

      .step__main {
        position: absolute;
        top: 80px;
        right: 0;
      }

      .step__question {
        width: 1020px;

        p {
          width: 820px;
        }
      }
    }
  }

  &__question {
    width: 828px;
    padding-top: 120px;
    padding-left: calc((100% - 1684px) / 2);
    box-sizing: border-box;

    &._with-img {
      
      h2 {
        width: 690px;
      }
    }

    h2 {
      width: 100%;
      margin-bottom: 8px;
      opacity: 0;
      transform: translateY(60px);
      transition: all .25s .3s ease-in-out;
    }

    p {
      width: 560px;
      font-family: 'InputMono';
      font-size: 20px;
      line-height: 38px;
      font-weight: 300;
      color: rgba(255,255,255,.7);
      margin: 0;
      opacity: 0;
      transform: translateY(60px);
      transition: all .25s .4s ease-in-out;

      &.title-desc {
        width: 100%;
        font-family: 'InputMono';
        font-size: 32px;
        line-height: 35px;
        font-weight: 500;
        color: #fff;
        letter-spacing: -.01em;
        margin: 0;
        margin-bottom: 40px;
      }
    }
  }

  &__main {
    display: flex;   
    justify-content: flex-end;
    box-sizing: content-box;
  }

  &__images {
    width: 394px;
    height: calc(100vh - 160px);
    padding-top: 80px;
    box-sizing: border-box;
    position: relative;

    &__item {
      width: 100%;
      height: calc(100vh - 162px);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom center;
      opacity: 0;
      visibility: hidden;
      transition: all .25s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(7, 20, 48, 0.37) 0%, rgba(228, 0, 49, 0.3) 50.52%, rgba(15, 65, 180, 0.5) 100%);
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
      }

      &._show {
        opacity: 1;
        visibility: visible;
        z-index: 5;
      }

      &._selected {
        opacity: 1;
        visibility: visible;
        z-index: 4;
      }
    }
  }

  &__answers {
    width: 696px;
    height: calc(100vh - 162px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-left: 1px solid rgba(255,255,255,.15);
    box-sizing: border-box;
    opacity: 0;
    transition: opacity .4s .4s ease-in-out;

    &__item {
      width: 100%;
      height: calc(100vh - 162px);
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      transition: all .5s ease-in-out;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      &._type-2 {
        height: calc((100vh - 162px) / 2);
      }

      &._type-3 {
        height: calc((100vh - 162px) / 3);
      }

      &._type-4 {
        height: calc((100vh - 162px) / 4);
      }

      &._type-5 {
        height: calc((100vh - 162px) / 5);
      }

      &._active {
        background: #0F41B4;

        span {
          color: #fff;
        }

        p.answer-desc {
          opacity: 1;
          visibility: visible;
        }

        &:hover {
          background: #0F41B4;
        }
      }

      &:hover {
        background: linear-gradient(180deg, rgba(16, 47, 122, 0) 0%, #102F7A 100%);

        span {
          color: #fff;
        }

        p.answer-desc {
          opacity: 1;
          visibility: visible;
        }
      }

      &:not(:first-of-type) {
        border-top: 1px solid rgba(255,255,255,.15);
      }

      span {
        font-family: 'InputMono';
        font-size: 40px;
        line-height: 48px;
        font-weight: 300;
        color: rgba(255,255,255,.5);
        text-transform: uppercase;
      }

      p.answer-desc {
        width: 416px;
        font-family: 'InputMono';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #fff;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: all .25s ease-in-out;
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);
      }

      .mobile-img {
        display: none;
      }
    }
  }

  &__info {
    width: 696px;
    height: calc(100vh - 160px);
    border-left: 1px solid rgba(255,255,255,.15);
    box-sizing: border-box;
    opacity: 0;
    transition: opacity .4s .4s ease-in-out;
  }

  &__arrow {
    width: 946px;
    height: 230px;
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity .2s .7s ease-in-out, transform .3s .7s ease-in-out;
    position: absolute;
    left: 0;
    bottom: 200px;

    &._hide {
      opacity: 0;
      visibility: hidden;
    }

    img {
      width: 100%;
    }
  }

  &__next {
    width: 100%;
    height: 83px;
    border-top: 1px solid rgba(255,255,255,.15);
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(80px);
    transition: all .3s .9s ease-in-out;
    position: absolute;
    left: 0;
    bottom: 0;

    &__counter {
      width: 116px;
      height: 80px;
      display: flex;
      align-items: center;

      span {
        font-family: 'InputMono';
        font-size: 24px;
        line-height: 29px;
        font-weight: 300;
        color: #fff;
        letter-spacing: 0.02em;
      }
    }

    &__btn {
      width: 696px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
    }
  }
}

// desktop
@media screen and (min-width: $desktop1_min) and (max-width: $desktop1_max) {
  
  .step {

    &__content {
      padding-top: 68px;

      &._two {

        .step__main {
          top: 68px;
        }

        .step__question {
          width: 700px; 

          p {
            width: 440px;
          }
        }
      }
    }
  
    &__question {
      width: 560px;
      padding-top: 96px;
      padding-left: calc((100% - 1280px) / 2);
  
      &._with-img {
        
        h2 {
          width: 414px;
        }
      }
  
      p {
        width: 440px;
        font-size: 16px;
        line-height: 30px;

        &.title-desc {
          font-size: 22px;
          line-height: 25px;
          margin-bottom: 24px;
        }
      }
    }
  
    &__images {
      width: 274px;
      height: calc(100vh - 124px);
      padding-top: 68px;
  
      &__item {
        height: calc(100vh - 124px);
      }
    }
  
    &__answers {
      width: 484px;
      height: calc(100vh - 124px);
  
      &__item {
        height: calc(100vh - 124px);
        overflow: hidden;
  
        &._type-2 {
          height: calc((100vh - 124px) / 2);
        }
  
        &._type-3 {
          height: calc((100vh - 124px) / 3);
        }
  
        &._type-4 {
          height: calc((100vh - 124px) / 4);
        }
  
        &._type-5 {
          height: calc((100vh - 124px) / 5);
        }
  
        span {
          font-size: 24px;
          line-height: 29px;
        }
  
        p.answer-desc {
          font-size: 14px;
          line-height: 21px;   
          bottom: 24px;
        }
      }
    }
  
    &__info {
      width: 484px;
      height: calc(100vh - 124px);
    }
  
    &__arrow {
      width: 604px;
      height: 148px;
      bottom: 166px;
    }
  
    &__next {
      height: 56px;
  
      &__counter {
        width: 80px;
        height: 56px;
  
        span {
          font-size: 16px;
          line-height: 19px;
        }
      }
  
      &__btn {
        width: 484px;
      }
    }
  }
}

@media screen and (min-width: $desktop2_min) and (max-width: $desktop2_max) {

  .step {

    &__content {
      padding-top: 68px;

       &._two {
        
        .step__main {
          top: 68px;
        }

        .step__question {
          width: 560px;

          p {
            width: 420px;
          }
        }
      }
    }
  
    &__question {
      width: 460px;
      padding-top: 96px;
      padding-left: calc((100% - 1160px) / 2);
  
      &._with-img {
        
        h2 {
          width: 460px;
        }
      }

      p {
        width: 100%;
        font-size: 16px;
        line-height: 26px;
      }
    }
  
    &__images {
      width: 280px;
      height: calc(100vh - 124px);
      padding-top: 68px;
  
      &__item {
        height: calc(100vh - 124px);
      }
    }
  
    &__answers {
      width: 460px;
      height: calc(100vh - 124px);
  
      &__item {
        width: 100%;
        height: calc(100vh - 124px);
  
        &._type-2 {
          height: calc((100vh - 124px) / 2);
        }
  
        &._type-3 {
          height: calc((100vh - 124px) / 3);
        }
  
        &._type-4 {
          height: calc((100vh - 124px) / 4);
        }
  
        &._type-5 {
          height: calc((100vh - 124px) / 5);
        }
  
        span {
          font-size: 22px;
          line-height: 34px;
        }
  
        p.answer-desc {
          width: 380px;
          font-size: 12px;
          line-height: 18px;
          bottom: 25px;
        }
      }
    }
  
    &__info {
      width: 460px;
      height: calc(100vh - 124px);
    }
  
    &__arrow {
      width: 580px;
      height: 144px;
      bottom: 140px;
    }
  
    &__next {
      height: 56px;
  
      &__counter {
        width: 80px;
        height: 56px;
  
        span {
          font-size: 16px;
          line-height: 20px;
        }
      }
  
      &__btn {
        width: 460px;
      }
    }
  }
}

// tablet-horizontal
@media screen and (min-width: $tablet-horizontal_min) and (max-width: $tablet-horizontal_max) {
  
  .step {
  
    &__content {
      padding-top: 60px;
  
      &._two {
  
        .step__main {
          position: absolute;
          top: 60px;
          right: 0;
        }
  
        .step__question {
          width: 490px;
  
          p {
            width: 400px;
          }
        }
      }
    }
  
    &__question {
      width: 440px;
      padding-left: calc((100% - 926px) / 2);
  
      &._with-img {
        
        h2 {
          width: 100%;
        }
      }
  
      p {
        width: 400px;
        font-size: 18px;
        line-height: 34px;
  
        &.title-desc {
          font-size: 26px;
          line-height: 34px;
          margin-bottom: 30px;
        }
      }
    }
  
    &__images {
      display: none;
    }
  
    &__answers {
      width: 460px;
      height: calc(100vh - 120px);
  
      &__item {
        height: calc(100vh - 120px);
  
        &._type-2 {
          height: calc((100vh - 120px) / 2);
        }
  
        &._type-3 {
          height: calc((100vh - 120px) / 3);
        }
  
        &._type-4 {
          height: calc((100vh - 120px) / 4);
        }
  
        &._type-5 {
          height: calc((100vh - 120px) / 5);
        }
  
        &:hover {
          background: transparent;
  
          span {
            color: rgba(255,255,255,.5);
          }
  
          p.answer-desc {
            opacity: 0;
            visibility: hidden;
          }
        }

        &._active {
          background: #0F41B4;
  
          span {
            color: #fff;
          }
  
          p.answer-desc {
            opacity: 1;
            visibility: visible;
          }
  
          &:hover {
            background: #0F41B4;

             span {
              color: #fff;
            }
          }
        }
  
        span {
          font-size: 36px;
          line-height: 44px;
        }
  
        p.answer-desc {
          bottom: 30px;
        }
      }
    }
  
    &__info {
      width: 460px;
      height: calc(100vh - 120px);
    }
  
    &__arrow {
      width: 460px;
      height: 114px;
      bottom: 110px;
    }
  
    &__next {
      height: 61px;
      transform: translateY(60px);
  
      &__counter {
        width: 100px;
        height: 60px;
  
        span {
          font-size: 18px;
          line-height: 24px;
        }
      }
  
      &__btn {
        width: 460px;

        button.btn__type-4 {
          height: 60px;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

// tablet-portrait
@media screen and (min-width: $tablet-portrait_min) and (max-width: $tablet-portrait_max) {

  .step {

    &._activeStep {
      display: block;

      .step__next {
        opacity: 1;
        transform: translateY(60px);

        &._show {
          transform: translateY(0);
        }
      }
    }

    &__content {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 60px;
  
      &._two {
        justify-content: flex-start;
  
        .step__main {
          position: relative;
          top: auto;
        }
  
        .step__question {
          width: 720px;
          margin: 0 auto;
          margin-bottom: 64px;
  
          p {
            width: 100%;
          }
        }
      }
    }
  
    &__question {
      width: 720px;
      padding-top: 60px;
      padding-left: 0;
      margin: 0 auto;
      margin-bottom: 64px;
  
      &._with-img {
        
        h2 {
          width: 100%;
        }
      }
  
      p {
        font-size: 20px;
        line-height: 38px;
  
        &.title-desc {
          font-size: 26px;
          line-height: 32px;
          margin-bottom: 32px;
        }
      }
    }
  
    &__images {
      display: none;
    }
  
    &__answers {
      width: 100%;
      height: calc(100vh - 120px);
  
      &__item {
        height: calc(100vh - 120px);
  
        &._type-2 {
          height: 240px;
        }
  
        &._type-3 {
          height: 200px;
        }
  
        &._type-4 {
          height: 160px;
        }
  
        &._type-5 {
          height: 120px;
        }
  
        &:hover {
          background: transparent;
  
          span {
            color: rgba(255,255,255,.5);
          }
  
          p.answer-desc {
            opacity: 0;
            visibility: hidden;
          }
        }

        &._active {
          background: #0F41B4;
  
          span {
            color: #fff;
          }
  
          p.answer-desc {
            opacity: 1;
            visibility: visible;
          }
  
          &:hover {
            background: #0F41B4;

             span {
              color: #fff;
            }
          }
        }

        &:first-of-type {
          border-top: 1px solid rgba(255,255,255,.15);
        }
  
        &:not(:first-of-type) {
          border-top: 1px solid rgba(255,255,255,.15);
        }

        &:last-of-type {
          border-bottom: 1px solid rgba(255,255,255,.15);
        }

        span {
          font-size: 38px;
          line-height: 46px;
        }
  
        p.answer-desc {
          bottom: 30px;
        }
  
        .mobile-img {
          display: none;
        }
      }
    }
  
    &__info {
      width: 100%;
      height: calc(100vh - 120px);
    }
  
    &__arrow {
      display: none;
    }
  
    &__next {
      height: 60px;
      display: block;
      background: #fff;
      padding-left: 5%;
      padding-right: 5%;
      border-top: none;
      box-sizing: border-box;
      position: fixed;
      left: 0;
      bottom: 0;
      transform: translateY(60px);
      transition: all .3s ease-in-out;
      
      &._show {
        transform: translateY(0);
      }

      .container {
        width: 68px;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 5%;
        transform: translateY(-50%);
      }
  
      &__counter {
        width: 82px;
        height: 60px;

        span {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: 0.02em;
          color: #071430;
        }
      }
  
      &__btn {
        width: 140px;
        position: absolute;
        top: 50%;
        right: 5%;
        z-index: 5;
        transform: translateY(-50%);

        button.btn {
          height: 60px;
          display: flex;
          justify-content: flex-end;

          &._disabled {
            background: transparent;
          }

          &__type-4 {
            background: transparent;

            &:hover {
              
              span {
                color: rgba(7, 20, 48, .9);
              }
            }

            span {
              font-size: 22px;
              line-height: 28px;
              color: rgba(7, 20, 48, .9);

              &:after {
                content: '';
                width: 42px;
                height: 18px;
                display: inline-block;
                vertical-align: middle;
                background-image: url('../../../static/images/icons/arrow-btn.svg');
                background-repeat: no-repeat;
                background-size: contain;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
}

// mobile
@media screen and (min-width: $mobile_min) and (max-width: $mobile_max) {

  .step {
    display: none;

    &._activeStep {
      display: block;

      .step__next {
        opacity: 1;
        transform: translateY(60px);

        &._show {
          transform: translateY(0);
        }
      }
    }

    &__content {
      flex-direction: column;
      padding-top: 92px;
      //background: linear-gradient(180deg, rgba(7, 20, 48, 0.8) 33.18%, rgba(223, 0, 49, 0.8) 170.74%);
  
      &._two {
        justify-content: flex-start;
  
        .step__main {
          position: relative;
          top: auto;
          right: auto;
        }
  
        .step__question {
          width: 100%;
  
          p {
            width: 100%;
          }
        }
      }
    }
  
    &__question {
      width: 100%;
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 32px;
      border-bottom: 1px solid rgba(255,255,255,.2);
  
      &._with-img {
        
        h2 {
          width: 100%;
        }
      }
  
      h2 {
        width: 100%;
        margin-bottom: 0;
        padding-left: 5%;
        padding-right: 5%;
        box-sizing: border-box;
        opacity: 0;
        transform: translateY(60px);
        transition: all .25s .3s ease-in-out;
      }
  
      p {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        margin-top: 4px;
        padding-left: 5%;
        padding-right: 5%;
        box-sizing: border-box;
        opacity: 0;
        transform: translateY(60px);
        transition: all .25s .4s ease-in-out;
  
        &.title-desc {
          font-size: 18px;
          line-height: 29px;
          margin-bottom: 10px;
          color: rgba(255,255,255,.9);
        }
      }
    }
  
    &__main { 
      justify-content: flex-end;
    }
  
    &__images { 
      display: none;
    }
  
    &__answers {
      width: 100%;
      height: auto;
      border-left: none;
      margin-bottom: 56px;
      opacity: 0;
  
      &__item {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        transition: all .5s ease-in-out;
        overflow: hidden;
        position: relative;
        cursor: pointer;
  
        &._type-2 {
          height: 202px;
        }
  
        &._type-3 {
          height: 140px;
        }
  
        &._type-4 {
          height: 116px;
        }
  
        &._type-5 {
          height: 76px;
        }
  
        &:hover {
          background: transparent;
  
          span {
            color: rgba(255,255,255,.5);
          }
  
          p.answer-desc {
            opacity: 0;
            visibility: hidden;
          }
        }

        &._active {
          background: #0F41B4;
  
          span {
            color: #fff;
          }
  
          p.answer-desc {
            opacity: 1;
            visibility: visible;
          }
  
          &:hover {
            background: #0F41B4;

             span {
              color: #fff;
            }
          }
        }
  
        &:not(:first-of-type) {
          border-top: 1px solid rgba(255,255,255,.15);
        }

        &:last-of-type {
          border-bottom: 1px solid rgba(255,255,255,.15);
        }
  
        span {
          font-size: 18px;
          line-height: 22px;
        }
  
        p.answer-desc {
          width: 288px;
          font-size: 12px;
          line-height: 18px;
          bottom: 32px;
        } 
      }
    }
  
    &__info {
      width: 100%;
      height: auto;
      border-left: none;
    }
  
    &__arrow {
      display: none;
    }
  
    &__next {
      height: 56px;
      display: block;
      background: #fff;
      padding-left: 5%;
      padding-right: 5%;
      border-top: none;
      box-sizing: border-box;
      position: fixed;
      left: 0;
      bottom: 0;
      transform: translateY(60px);
      transition: all .3s ease-in-out;
      
      &._show {
        transform: translateY(0);
      }

      .container {
        width: 68px;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 5%;
        transform: translateY(-50%);
      }
  
      &__counter {
        width: 68px;
        height: 56px;

        span {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.02em;
          color: #071430;
        }
      }
  
      &__btn {
        width: 128px;
        position: absolute;
        top: 50%;
        right: 5%;
        z-index: 5;
        transform: translateY(-50%);

        button.btn {
          height: 56px;
          display: flex;
          justify-content: flex-end;

          &._disabled {
            background: transparent;
          }

          &__type-4 {
            background: transparent;

            &:hover {
              
              span {
                color: rgba(7, 20, 48, .9);
              }
            }

            span {
              font-size: 18px;
              line-height: 22px;
              color: rgba(7, 20, 48, .9);

              &:after {
                content: '';
                width: 42px;
                height: 18px;
                display: inline-block;
                vertical-align: middle;
                background-image: url('../../../static/images/icons/arrow-btn.svg');
                background-repeat: no-repeat;
                background-size: contain;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
}
