.withInfiniteMove {
    overflow: hidden;

    &__track {
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        font-size: 0;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        & > * {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &._moving-left {

        .withInfiniteMove__track {
            animation-name: move-left;
        }
    }

    &._moving-right {
        
        .withInfiniteMove__track {
            animation-name: move-right;
        }
    }
}


@keyframes move-left {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
    }
}

@keyframes move-right {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0%);
    }
}